import Intro1 from "./images/Intro1.mp4";

import Intro3 from "./images/Intro3.mp4";

import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import "./App.css";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { Navbar } from "./components/Navbar/Navbar";
import { About } from "./pages/About/About";
import { Home } from "./pages/Home/Home";
import { PortfolioPage } from "./pages/Portfolio/PortfolioPage";
import { Team } from "./pages/Team/Team";
import { Blog } from "./pages/Blog/Blog";
import { Investor } from "./pages/Investor/Investor";
import { Footer } from "./components/footer/Footer";
import { BlogPost } from "./pages/Blog/BlogPost";
import { useEffect, useState } from "react";

import { Gototop } from "./components/Gototop/Gototop";
import { AnimatePresence } from "framer-motion";

import { ToastContainer } from "react-toastify";

import { LandingPage } from "./pages/SA/LandingPage";
import { BlogPosts } from "./pages/SA/BlogPosts";
import Kenya from "./pages/Kenya/Kenya";
import Ghana from "./pages/Ghana/Ghana";
import { BlogPostk } from "./pages/Kenya/BlogPostk";
import { BlogPostg } from "./pages/Ghana/BlogPostg";
import { BlogPostu } from "./pages/Uganda/BlogPostu";
import { BlogPoste } from "./pages/Egypt/BlogPoste";
import { BlogPostm } from "./pages/Mexico/BlogPostm";

import { BlogPostsi } from "./pages/SierraLeone/BlogPostsi";

import Egypt from "./pages/Egypt/Egypt";
import SierraLeone from "./pages/SierraLeone/SierraLeone";
import Uganda from "./pages/Uganda/Uganda";
import Mexico from "./pages/Mexico/Mexico";
import United from "./pages/USA/United";
import { BlogPostus } from "./pages/USA/BlogPostus";
import AfricaWalk from "./pages/AfricaWalk/AfricaWalk";

function App() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 6000);
	}, []);
	useEffect(() => {
		if (localStorage.length === 0) {
			localStorage.setItem("nigeria", "Select region");
			localStorage.setItem("nigerias", "Select region");
		}
	}, []);

	const Routing = () => {
		const location = useLocation();

		return (
			<AnimatePresence exitBeforeEnter>
				<Routes key={location.pathname} location={location}>
					<Route path="/" element={<Home />} />
					<Route path="/portfolio" element={<PortfolioPage />} />
					<Route path="/team" element={<Team />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/about" element={<About />} />
					<Route path="/africawalk" element={<AfricaWalk />} />
					<Route
						path="http://platforminvestorportal.com"
						element={<Investor />}
					/>
					<Route path="/blogpost/:slug" element={<BlogPost />} />
					<Route path="/blogposts/:slug" element={<BlogPosts />} />
					<Route path="/blogpostk/:slug" element={<BlogPostk />} />
					<Route path="/blogpostg/:slug" element={<BlogPostg />} />
					<Route path="/blogposte/:slug" element={<BlogPoste />} />
					<Route path="/blogpostu/:slug" element={<BlogPostu />} />
					<Route path="/blogpostsi/:slug" element={<BlogPostsi />} />
					<Route path="/blogpostm/:slug" element={<BlogPostm />} />
					<Route path="/blogpostus/:slug" element={<BlogPostus />} />

					<Route path="/sa" element={<LandingPage />} />
					<Route path="/kenya" element={<Kenya />} />
					<Route path="/ghana" element={<Ghana />} />
					<Route path="/egypt" element={<Egypt />} />
					<Route path="/uganda" element={<Uganda />} />
					<Route path="/sierra" element={<SierraLeone />} />
					<Route path="/mexico" element={<Mexico />} />
					<Route path="/usa" element={<United />} />

					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</AnimatePresence>
		);
	};

	return (
		<div className="">
			{loading ? (
				<div>
					<div>
						<video
							className="loader hide-on-med-and-up"
							style={{
								objectFit: "scale-down",
								objectPosition: "center center",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								width: "100%",
								margin: "0 auto",
							}}
							id="myVideo"
							src={Intro1}
							autoPlay
							muted
							type="video/mp4"
						></video>
					</div>
					<div>
						<video
							className="loader hide-on-small-only"
							style={{
								objectFit: "scale-down",
								objectPosition: "center center",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								minWidth: "100%",
								margin: "0 auto",
							}}
							id="myVideo"
							src={Intro3}
							autoPlay
							muted
							type="video/mp4"
						></video>
					</div>
					
				</div>
			) : (
				<Router>
					<ToastContainer />
					<Navbar />

					<Routing />

					<Gototop />
					<Footer />
				</Router>
			)}
		</div>
	);
}

export default App;
