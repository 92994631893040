import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";

import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import "aos/dist/aos.css";

import "react-toastify/dist/ReactToastify.css";

import "react-multi-carousel/lib/styles.css";
import "react-share-social/dist/ShareButtons/FacebookShare.js";
import "react-share-social/dist/ShareButtons/TwitterShare";
import "react-share-social/dist/ShareButtons/LinkedinShareButton";
import "react-lazy-load-image-component/src/effects/blur.css";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
