import React, { useEffect } from "react";
import "./OurMission.css";
import AOS from "aos";

const OurMission = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <div className="our-footprint-about">
        <div>
          <h6 className="footprint-about">Value proposition</h6>
          <h3 className="passionate-about">
            We are building the Africa of the future; the new Africa.
          </h3>
        </div>
      </div>

      {/* largest vc in africa */}

      <div className="mission-bottom">
        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Experienced Team</h6>
            <p>
              Highly focused, top culture investment professionals;who have
              skin-in-the game and are aligned with investors and companies.
            </p>
          </div>
        </div>

        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Unique Investment Strategy</h6>
            <p>
              We create fast-growing, market leading companies, by leveraging
              our local and global network as well as our experience, to add
              value to our clients businesses.
            </p>
          </div>
        </div>

        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Diverse Ecosystem</h6>
            <p>
              We have cultivated a close knit web of highly skilled, multi
              dimentional individuals that act as a support system across each
              vertical.
            </p>
          </div>
        </div>
        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Access to Global Deals</h6>
            <p>
              We have earned exclusive opportunities sourced through our global
              network, by postioning ourselves as the dominant force in our
              industry.
            </p>
          </div>
        </div>
        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Credible Track Record</h6>
            <p>
              As industry leaders, we have demonstrated a standard of excellence
              that serves as evidence of our capacity.
            </p>
          </div>
        </div>

        <div className="award3">
          <div className="award3-icons">
            <i className="fas fa-dharmachakra fa-2x award3-icon"></i>
          </div>
          <div className="BIF">
            <h6>Attractive Investment Structures</h6>
            <p>
              Exposure through investment structures capable of providing
              protection whilst unlocking equity throughout the business cycle.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
