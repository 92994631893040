import Aos from "aos";
import React, { useEffect } from "react";
import "./Portfolio1.css";

const Portfolio1 = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  return (
    <div>
      <div className="porfolio-company">
        <h2>Our portfolio</h2>
        <p>
          We view the success of our portfolio companies as a collective effort.
        </p>
      </div>

      <div className="main-direct-parent hide-on-med-and-down">
        <div className="main-direct">
          <div className="direct">
            <h1>161+</h1>
            <h6>Portfolio Companies (Direct + Indirect)</h6>
          </div>
          <div className="direct1">
            <h1>8</h1>
            <h6>Offices on a global scale</h6>
          </div>
        </div>

        <div className="main-direct2">
          <div className="direct2">
            <h1>11</h1>
            <h6>Nationalities</h6>
          </div>
          <div className="direct3">
            <h1>70+</h1>
            <h6>Investment team (400+ Investment & operations team)</h6>
          </div>
        </div>

        <div className="main-direct4">
          <div className="direct4">
            <h1>$1.5bn+</h1>
            <h6>Portfolio valuation (Dec 2021)</h6>
          </div>
          <div className="direct5">
            <h1>9</h1>
            <h6>Sector verticals</h6>
          </div>
        </div>
      </div>
      {/* mobile version */}
      <div className="PortMobil hide-on-med-and-up">
        <div className="redirect">
          <h1>123+</h1>
          <h6>Companies (Direct + Indirect)</h6>
        </div>

        <div className="redirect01">
          <h1>3</h1>
          <h6>Continents on a global scale</h6>
        </div>

        <div className="redirect02">
          <h1>15</h1>
          <h6>Countries we opperate</h6>
        </div>

        <div className="redirect03">
          <h1>3,000</h1>
          <h6>Employees accross out portfolio (Direct + Indirect)</h6>
        </div>

        <div className="redirect04">
          <h1>$1bn+</h1>
          <h6>Portfolio valuation </h6>
        </div>

        <div className="redirect05">
          <h1>9</h1>
          <h6>Sectors of operation</h6>
        </div>
      </div>
    </div>
  );
};

export default Portfolio1;
