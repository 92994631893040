import React, { useEffect, useState } from "react";
import "./Newsleter.css";
import AOS from "aos";
import axios from "axios";

const Newsletter = () => {
  const [email, setEmail] = useState("");
 

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const data = await axios.post("/newsletter", {
        email,
        
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="hide-on-med-and-down">
      <div className="general-news" data-aos="zoom-in">
        <div className="newsletter">
          <h2>Suscribe to our newsletter</h2>
          <div className="news">
            <p>
              We leverage our local presence, global network, operating
              experience, technical partnerships, and knowledge to champion
              businesses.
            </p>
          </div>
         
          <div className="input-field">
            <i className="material-icons prefix" style={{ color: "#6BA6FF" }}>
              mail
            </i>
            <input
              id="icon_prefix"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="icon_prefix">Your email address</label>
          </div>
          <span>
            <button className="btn" onClick={handleSubmit}>
              Submit
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
