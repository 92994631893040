import React from "react";



import saferoomLogo from "../../images/saferoomLogo.png";
import macLogo from "../../images/macLogo.png";
import soloLogo from "../../images/soloLogo.png";
import rxailLogo from "../../images/rxailLogo.png";

import bookingsLogo from "../../images/bookingsLogo.png";
import konikulogo from "../../images/konikulogo.png";
import unicornlogo from "../../images/unicornlogo.png";
import diatomimpactlogo from "../../images/diatomimpactlogo.png";
import mobaby from "../../images/mobaby.svg";

import "./PortfolioCompany.css";

export const PorfolioCompany = () => {
  return (
    <div className=" container ">
      <div className="africa ">
        <div className="bookings">
          <div className="bookings-top">
            <img src={bookingsLogo} />
          </div>
          <div className="bookings-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Join Africa’s gig marketplace and find great talent on-demand for
              services.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Bookings Africa leverages technology to disrupt and revolutionise
              talent sourcing in the entertainment & media, fashion, health &
              beauty, lifestyle and professional industries across Africa.
            </p>
            <a
              href="https://bookingsafrica.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Bookings Africa
            </a>
          </div>
        </div>

        <div className="koniku">
          <div className="koniku-top">
            <img src={konikulogo} />
          </div>
          <div className="koniku-body">
            <h6 className="hide-on-med-and-down">Biotech</h6>
            <h5>
              Koniku is a synthetic biotechnology company that built a smell
              cyborg.
            </h5>
            <h6 className="hide-on-large-only">Biotech</h6>
            <p>
              Koniku is a leading bio-technology company that has successfully
              merged synthetic neurobiology with silicon to develop the world’s
              first fully functional Volatile Organic Compound (VOC) Sensor.
            </p>
            <a
              href="https://koniku.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Koniku
            </a>
          </div>
        </div>
        <div className="unicorn">
          <div className="unicorn-top">
            <img src={unicornlogo} />
          </div>
          <div className="unicorn-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Meet Unicorn solving Africa’s problems through innovation and
              technology
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>

            <p>
              We provide infrastructure, training, mentorship, market access and
              patient capital for entrepreneurs, technology start-ups and early
              stage companies to address the challenges that they encounter.
            </p>
            <a
              href="https://www.unicornmaking.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Unicorn Group
            </a>
          </div>
        </div>
        <div
          className="diatom"
         
        >
          <div className="diatom-top">
            <img src={diatomimpactlogo} />
          </div>
          <div className="diatom-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Join Diatom Impact to change the African narrative through direct
              impact projects.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              We aim to transform Africa by building a powerful village of local
              and global changemakers. Together we will change the narrative and
              practice of investment and development in Africa.
            </p>
            <a
              href="https://www.diatomimpact.com"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Diatom Impact
            </a>
          </div>
        </div>

       
        <div className="mobaby">
          <div className="mobaby-top">
            <img src={mobaby} />
          </div>
          <div className="mobaby-body">
            <h6 className="hide-on-med-and-down">Consumer goods</h6>
            <h5>Natural Solutions for Baby's Delicate Needs</h5>
            <h6 className="hide-on-large-only">Consumer goods</h6>
            <p>
              A lot of care is put into the formulations of MOBABY CARE with
              natural botanicals like shea butter, coconut oil, aloe vera, oat
              extract, you can feel confident knowing each product caters to
              your little one’s delicate needs.
            </p>
            <a
              href="https://mobabycare.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Mobaby
            </a>
          </div>
        </div>

        {/* saferoom */}

        <div className="saferoom">
          <div className="saferoom-top">
            <img src={saferoomLogo} className="responsive-img" />
          </div>
          <div className="saferoom-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              SafeRoom offers a secure, customizable and private communication
              messenger
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              SafeRoom is a SaaS platform that offers secure mobile messaging
              for businesses or teams combining security, customization, and
              ease of use.
            </p>
            <a
              href="https://www.saferoom.io"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Saferoom
            </a>
          </div>
        </div>

        {/* Mac */}

        <div className="mac">
          <div className="mac-top">
            <img src={macLogo} className="responsive-img" />
          </div>
          <div className="mac-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Mac Ventures an early stage Venture Capital fund interested in
              tech companies
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              MaC Venture Capital is a seed-stage venture capital firm that
              invests in technology startups leveraging shifts in cultural
              trends and behaviors.
            </p>
            <a
              href="https://macventurecapital.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit MaC Ventures
            </a>
          </div>
        </div>

        {/* solo */}

        <div className="solo">
          <div className="solo-top">
            <img src={soloLogo} className="responsive-img" />
          </div>
          <div className="solo-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              SoLo is one of the leading financial tech companies for
              underserved communities.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              SoLo is a marketplace community allowing members to easily access
              and supply short-term funds for immediate needs.
            </p>
            <a
              href="https://solofunds.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Solo Funds
            </a>
          </div>
        </div>

        {/* rxail */}

        <div className="rxail">
          <div className="rxail-top">
            <img src={rxailLogo} className="responsive-img" />
          </div>
          <div className="rxail-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Rxail built a device for instant drug testing to Identify the
              quality of prescription meds
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              RxAll is a trusted marketplace for quality medicines providing
              on-demand delivery of authenticated high-quality medicines to
              hospitals, pharmacies and patients.
            </p>
            <a
              href="https://www.rxall.net"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Rxail
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
