import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

import moment from "moment";
import ReactPaginate from "react-paginate";
import M from "materialize-css";

import Newsletter from "../../components/Newsletter/Newsletter";
import { useDocumentTitle } from "../../components/TitleChange/TitleChange";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import Aos from "aos";
import sanityClient from "../../client";

export const Blog = () => {
	useDocumentTitle("Platform | Blog");

	useEffect(() => {
		const elemsDropdown = document.querySelectorAll(".dropdown-trigger");
		M.Dropdown.init(elemsDropdown, {
			alignment: "right",
		});
		Aos.init({
			duration: 1200,
			// delay: 3000,
		});
	}, []);

	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [loading, setLoading] = useState(true);
	// const [search, setSearch] = useState("");
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	const itemsPerPage = 9;
	const endOffset = itemOffset + itemsPerPage;

	useEffect(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	const style1 = {
		// position: "absolute",
		top: "15rem",
		left: "1rem",
		background: "#39B68E",
		border: "none",
		padding: "0.4rem",
		// width: "10rem",
		// height: "2rem",

		borderRadius: " 12px",
	};
	const style2 = {
		// position: "absolute",
		top: "15rem",
		left: "1rem",
		background: "#2B7FFF",
		border: "none",
		padding: "0.4rem",
		// width: "10rem",
		// height: "2rem",

		borderRadius: " 12px",
	};
	const style3 = {
		// position: "absolute",
		top: "15rem",
		left: "1rem",
		background: "#D9B536",
		border: "none",
		padding: "0.4rem",
		// width: "10rem",
		// height: "2rem",

		borderRadius: " 12px",
	};
	const style4 = {
		// position: "absolute",
		top: "15rem",
		left: "1rem",
		background: "purple",
		border: "none",
		padding: "0.4rem",
		// width: "10rem",
		// height: "2rem",

		borderRadius: " 12px",
	};

	const handleAll = () => {
		getData();
		// const All = document.getElementById("all").textContent;
		// console.log(All);
		// const data = currentItems.filter((x) => x.categories != All.toString());
		// setCurrentItems(data.slice(itemOffset, endOffset));
		// setLoading(false);
		// setPageCount(Math.ceil(data.length / itemsPerPage));

		// const endOffset = itemOffset + itemsPerPage;
		// try {
		//   const record = await axios.get("/categories/all");
		//   const records = record.data;

		//   setCurrentItems(records.slice(itemOffset, endOffset));
		//   setPageCount(Math.ceil(records.length / itemsPerPage));
		// } catch (error) {
		//   console.log(error.message);
		// }
	};

	// const handleLatest = () => {
	//   const Latest = document.getElementById("latest").textContent;
	//   console.log(Latest);
	//   const data = currentItems.filter((x) => x.categories != Latest.toString());
	//   setCurrentItems(data.slice(itemOffset, endOffset));
	//   setLoading(false);
	//   setPageCount(Math.ceil(data.length / itemsPerPage));
	//   const endOffset = itemOffset + itemsPerPage;
	//   try {
	//     const record = await axios.get("/categories/latest");
	//     const records = record.data;
	//     setCurrentItems(records.slice(itemOffset, endOffset));
	//     setPageCount(Math.ceil(records.length / itemsPerPage));
	//   } catch (error) {
	//     console.log(error.message);
	//   }
	// };

	const handleEvents = () => {
		sanityClient
			.fetch(
				`*[_type == "post" && categories == "Event"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
			)
			.then((data) => {
				console.log(data);
				setCurrentItems(data.slice(itemOffset, endOffset));
				setLoading(false);
				setPageCount(Math.ceil(data.length / itemsPerPage));
			})
			.catch((error) => {
				console.log(error);
			});

		// Fetch items from another resources.

		//console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	};

	const handleInvestments = () => {
		sanityClient
			.fetch(
				`*[_type == "post" && categories == "Investment"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
			)
			.then((data) => {
				console.log(data);
				setCurrentItems(data.slice(itemOffset, endOffset));
				setLoading(false);
				setPageCount(Math.ceil(data.length / itemsPerPage));
			})
			.catch((error) => {
				console.log(error);
			});

		// Fetch items from another resources.

		//console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	};
	const handleInitiatives = () => {
		sanityClient
			.fetch(
				`*[_type == "post" && categories == "Initiative"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
			)
			.then((data) => {
				console.log(data);
				setCurrentItems(data.slice(itemOffset, endOffset));
				setLoading(false);
				setPageCount(Math.ceil(data.length / itemsPerPage));
			})
			.catch((error) => {
				console.log(error);
			});

		// Fetch items from another resources.

		//console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	};

	// We start with an empty list of items.

	// const items = data;

	useEffect(() => {
		getData();
	}, [itemOffset, itemsPerPage]);

	const getData = () => {
		// const items = await axios.get("/post");
		sanityClient
			.fetch(
				`*[_type == "post"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
			)
			.then((data) => {
				console.log(data);
				setCurrentItems(data.slice(itemOffset, endOffset));
				setLoading(false);
				setPageCount(Math.ceil(data.length / itemsPerPage));
			})
			.catch((error) => {
				console.log(error);
			});

		// Fetch items from another resources.

		//console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	};
	// Invoke when user clic000k to request another page.
	const handlePageClick = async (event) => {
		window.scroll({
			top: 300,
			left: 0,
			behavior: "smooth",
		});
		console.log(currentItems);
		// const items = await axios.get("/post");
		// const newOffset = (event.selected * itemsPerPage) / currentItems.length;
		const newOffset = event.selected * itemsPerPage;
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setItemOffset(newOffset);
	};

	return (
		<AnimatePage>
			<div>
				<div className="container connected">
					<h1>Stay connected and find out what’s going on</h1>
				</div>
				<div className="blog">
					<div className="buttons">
						<button onClick={handleAll} className=" btn">
							All
						</button>

						<button onClick={handleEvents} className=" btn">
							Events
						</button>
						<button onClick={handleInvestments} className=" btn">
							Investments
						</button>
						<button onClick={handleInitiatives} className=" btn">
							Initiatives
						</button>
					</div>
				</div>

				{/* cards */}

				{/* {renderBlogs()} */}
				<div className="container-fluid main-card">
					{!loading ? (
						currentItems.map((item, index) => {
							return (
								<div className="card-style" key={index}>
									{/* <Link to={`/blogpost/${item._id}`}> */}
									<Link
										to={"/blogpost/" + item.slug.current}
										key={item.slug.current}
									>
										<div className="card-image resize">
											{/* <img src={item.image} className="responsive-img resize" /> */}
											<img
												src={item.mainImage.asset.url}
												className="responsive-img"
											/>
										</div>
									</Link>
									<div className="content">
										{item.tags == "Announcement" ? (
											<div className="para">
												<button style={style1}>{item.tags}</button>
											</div>
										) : item.tags == "Technology" ? (
											<div className="para">
												<button style={style2}>{item.tags}</button>
											</div>
										) : item.tags == "Tips" ? (
											<div className="para">
												<button style={style3}>{item.tags}</button>
											</div>
										) : (
											<div className="para">
												<button style={style4}>Others</button>
											</div>
										)}

										{/* <Link to={`/blogpost/${item._id}`}> */}
										<div className="title-space">
											<Link to={"/blogpost/" + item.slug.current}>
												<h6
												// dangerouslySetInnerHTML={{ __html: item.title }}
												// style={{ height: "100px" }}
												>
													{item.title}
												</h6>
											</Link>
										</div>

										<div className="bottoms">
											<span>
												{moment(item.publishedAt).format("Do MMM YYYY")}
												{/* ({new Date(item.publishedAt).toDateString()}) */}
											</span>
											{/* <span>{item.publishedAt}</span> */}
											{/* <span>7 mins read</span> */}
										</div>
									</div>
								</div>
							);
						})
					) : (
						<i
							className="fas fa-spinner fa-spin fa-5x spinner"
							style={{ color: "white" }}
						></i>
					)}
				</div>

				<ReactPaginate
					nextLabel="next >"
					onPageChange={handlePageClick}
					pageCount={pageCount}
					previousLabel="< previous"
					renderOnZeroPageCount={null}
					containerClassName={"pagination"}
					pageClassName={"pageClass"}
					activeLinkClassName={"activeLink"}
					disabledClassName={"disable"}
				/>
				<div className="container">
					<Newsletter />
				</div>
			</div>
		</AnimatePage>
	);
};
