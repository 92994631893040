import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import moment, { relativeTimeRounding } from "moment";
import "./United.css";

import { Link } from "react-router-dom";
import Video from "../Images/VideoIcon.svg";

import LinkedIn from "../Images/LinkedIn.svg";
import Instagram from "../Images/Instgram.svg";
import Mail from "../Images/Mail.svg";
import YouTube from "../Images/YouTube.svg";

import Strap from "../Images/Strap.svg";
import Saferoom from "../Images/Saferoom.svg";
import Qapla from "../Images/Qapla.svg";
import Angel from "../Images/Angel.svg";

import Img1 from "../Images/Img1.svg";
import Img2 from "../Images/Img2.svg";
import Img3 from "../Images/Img3.svg";
import Img4 from "../Images/Img4.svg";
import Img5 from "../Images/Img5.svg";
import Img6 from "../Images/Img6.svg";

import Advisory from "../Images/Advisory.svg";
import Investment from "../Images/Investment.svg";
import Bank from "../Images/Bank.svg";

function United() {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 9;
  const endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    getData();
  }, [itemOffset, itemsPerPage]);

  const getData = () => {
    // const items = await axios.get("/post");
    sanityClient
      .fetch(
        `*[_type == "post" && regions == "USA"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
      )
      .then((data) => {
        console.log(data);
        setCurrentItems(data.slice(itemOffset, endOffset));
        setLoading(false);
        setPageCount(Math.ceil(data.length / itemsPerPage));
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch items from another resources.

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  };

  const style1 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#39B68E",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style2 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#2B7FFF",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style3 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#D9B536",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style4 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "purple",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  return (
    <div>
      <header className="UnitedParent">
        <div className="container">
          <div className="UnitedMainContainer">
            <h6>Wecome to the United States</h6>
            <h3>Emerging in the #3 ranked SMME market in Africa </h3>
            <p>
              Platform Capital USA was officially launched in 2021 to provide a
              solution to the growing demand for structure and funding for
              SMME’s. We plan to capitalize on this fast growing market.
            </p>

            <div className="UnitedButton">
              <button>
                <Link to="">Learn more about us</Link>
              </button>
            </div>

            <div className="UnitedVideoClick">
              <div className="UnitedSubVid1">
                <Link to="">
                  <img src={Video} alt="" />
                </Link>
              </div>
              <div className="UnitedSubVid2">
                <p>Watch video</p>
              </div>
            </div>
          </div>
        </div>

        <div className="UnitedSocialMediaHandles">
          <Link to="">
            <img src={LinkedIn} alt="" />
          </Link>
          <Link to="">
            <img src={Instagram} alt="" />
          </Link>
          <Link to="">
            <img src={Mail} alt="" />
          </Link>
          <Link to="">
            <img src={YouTube} alt="" />
          </Link>
        </div>
      </header>

      <div className="United2GeneralConatiner">
        <div className="container">
          <div className="United2MainContainer">
            <div className="United2Children1">
              <h2>$1BN+</h2>
              <p>Portfolio Valuation</p>
            </div>

            <div className="United2Children">
              <h2>123+</h2>
              <p>Portfolio companies</p>
            </div>

            <div className="United2Children">
              <h2>60+</h2>
              <p>Employees</p>
            </div>

            <div className="United2Children">
              <h2>9</h2>
              <p>Sector Verticals</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="United3MainContainer">
          <h6>Regional Partners</h6>
          <h3>
            Our diverse footprint covers key industries across the continent
          </h3>
        </div>
      </div>

      <div className="container">
        <div className="United4MainContainer">
          <img src={Strap} alt="" />
          <img src={Saferoom} alt="" />
          <img src={Qapla} alt="" />
          <img src={Angel} alt="" />
        </div>
      </div>

      <div className="container">
        <div className="United5MainContainer">
          <div className="United5MainParent">
            <h6>Recent Activities</h6>
            <h3>Our preliminary work is underway in the United States</h3>
          </div>
        </div>
      </div>
      {/* start of blog */}
      <div className="container-fluid main-card">
        {!loading ? (
          currentItems.map((item, index) => {
            return (
              <div class="card-style" key={index}>
                {/* <Link to={`/blogpost/${item._id}`}> */}
                <Link
                  to={"/blogpostus/" + item.slug.current}
                  key={item.slug.current}
                >
                  {item.mainImage ? (
                    <div class="card-image resize">
                      {/* <img src={item.image} className="responsive-img resize" /> */}
                      <img
                        src={item.mainImage.asset.url}
                        className="responsive-img"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
                <div className="content">
                  {item.tags == "Announcement" ? (
                    <div className="para">
                      <button style={style1}>{item.tags}</button>
                    </div>
                  ) : item.tags == "Technology" ? (
                    <div className="para">
                      <button style={style2}>{item.tags}</button>
                    </div>
                  ) : item.tags == "Tips" ? (
                    <div className="para">
                      <button style={style3}>{item.tags}</button>
                    </div>
                  ) : (
                    <div className="para">
                      <button style={style4}>Others</button>
                    </div>
                  )}

                  {/* <Link to={`/blogpost/${item._id}`}> */}
                  <div className="title-space">
                    <Link to={"/blogpostus/" + item.slug.current}>
                      <h6
                      // dangerouslySetInnerHTML={{ __html: item.title }}
                      // style={{ height: "100px" }}
                      >
                        {item.title}
                      </h6>
                    </Link>
                  </div>

                  <div class="bottoms">
                    <span>
                      {moment(item.publishedAt).format("Do MMM YYYY")}
                      {/* ({new Date(item.publishedAt).toDateString()}) */}
                    </span>
                    {/* <span>{item.publishedAt}</span> */}
                    {/* <span>7 mins read</span> */}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <i
            class="fas fa-spinner fa-spin fa-5x spinner"
            style={{ color: "white" }}
          ></i>
        )}
      </div>
      {/* end of blog */}

      <div className="container">
        <div className="United6GeneralContainer">
          <div className="United6MainContainer">
            <h6>WHAT WE DO</h6>
            <h3>Our Investment Thesis</h3>
            <p>
              We cover three key areas which are Principal Investments, Advisory
              and Investment Fiduciary.
            </p>
          </div>

          <div className="United6Parent">
            <div className="United6Children">
              <img src={Advisory} alt="" />
              <h5>Principal Investment</h5>
              <p>
                We provide long-term capital to our investee companies, and work
                with them to achieve set goals. Long-term capital offers
                stability to the business, and allows entrepreneurs to focus on
                delivering the vision.
              </p>
            </div>

            <div className="United6Children">
              <img src={Investment} alt="" />
              <h5>Advisory</h5>
              <p>
                Our holistic approach towards identifying opportunities,
                structuring, negotiations, capital raise and active management
                makes us a unique partner across all our engagements.
              </p>
            </div>

            <div className="United6Children">
              <img src={Bank} alt="" />
              <h5>Investment Fiduciary</h5>
              <p>
                We leverage our senior leadership team’s experience across
                multiple sectors and geographies coupled with our
                investpreneurship skillset to manage third-party businesses and
                assets as fiduciaries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default United;
