import React, { useEffect } from "react";
import "./Accordion.css";
import M from "materialize-css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import mail from "../../images/mail.png";
import youtube from "../../images/youtube.png";
import instagram from "../../images/instagram.png";
import linkedIn from "../../images/linkedIn.png";

function Accordion() {
	useEffect(() => {
		var elems = document.querySelectorAll(".collapsible");
		M.Collapsible.init(elems, {});
	}, []);

	return (
		<div className="wrapper hide-on-med-and-up">
			<div className="brand-logo center footer-logo">
				<Link to="/">
					<img src={logo} className=" responsive-img" />
				</Link>
			</div>
			<ul className="collapsible">
				<li>
					<div className="collapsible-header">
						{" "}
						Company
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p>Our mission</p>
						<p>Our goals</p>
						<p>Our core values</p>
					</div>
				</li>

				<li>
					<div className="collapsible-header">
						Portfolio
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p>Sectors</p>
						<p>Companies</p>
					</div>
				</li>

				<li>
					<div className="collapsible-header">
						Industry
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p>Our mission </p>
						<p>Our goals</p>
						<p>Our core values</p>
					</div>
				</li>

				<li>
					<div className="collapsible-header">
						Media
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p>Blog</p>
						<p>Event calendar</p>
						<p>Newsletter</p>
					</div>
				</li>

				<li>
					<div className="collapsible-header">
						Our team
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p> Senior Management</p>
						<p>Associates</p>
						<p>Teams</p>
					</div>
				</li>

				<li>
					<div className="collapsible-header">
						Contact
						<div className="coli">
							<RiArrowDropDownLine className="coli2" />
						</div>
					</div>

					<div className="collapsible-body">
						<p> Office locations</p>
						<p>info@theplatformcapital.coms</p>
						<p></p>
					</div>
				</li>
			</ul>

			<div className="footer-icons-mobile">
				<a href="https://www.linkedin.com/company/theplatformcapital">
					<img src={linkedIn} alt="linkedIn" />
				</a>
				<a href="https://www.youtube.com/channel/UCBVDN0GxaRsmtXbsn11kmeQ/featured">
					<img src={youtube} alt="youtube" />
				</a>
				<a href="https://www.instagram.com/theplatformcapital">
					<img src={instagram} alt="instagram" />
				</a>
				<a href="info@theplatformcapital.com">
					<img src={mail} alt="mail" />
				</a>
			</div>
			<div className="reserved-rights">
				© Platform Capital Group 2022. All rights reserved.
			</div>
		</div>
	);
}

export default Accordion;
