import React, { useEffect } from "react";
import M from "materialize-css";

import "./Video.css";
import homeImg1 from "../../images/homeImg1.png";

import watch from "../../images/watch.png";
import CB from "../../images/CB.png";

const Video = () => {
	useEffect(() => {
		const elemVideo = document.querySelectorAll(".modal");
		M.Modal.init(elemVideo, {
			dismissible: false,
		});
	}, []);

	const removeIFrame = () => {
		const frame = (document.getElementById("iframe").src = "");
		window.location.reload();
	};

	return (
		<div>
			<div className="video-image-modal">
				<img src={homeImg1} alt="Video-player" className="responsive-img" />
				<h1>Changing the way the world sees Africa.</h1>
				<div className="button-videos">
					<button data-target="modal-video" className="btn modal-trigger">
						<img src={watch} alt="watch-video" /> Watch video
					</button>
				</div>
			</div>

			<div id="modal-video" className="modal">
				<div className="modal-content">
					<div className="modal-footer modal-close">
						<img src={CB} onClick={removeIFrame} alt="close button" />
					</div>
					<div className="video-container responsive-video">
						<iframe
							id="iframe"
							width="853"
							height="480"
							src="https://www.youtube.com/embed/iB0PA2QPGlA"
							title="Platform Capital Group"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Video;
