import React, { useEffect } from "react";
import Billboard from "../../images/Billboard.jpg";
import aw from "../../images/aw.png";

import "./AfricaWalk.css";
import M from "materialize-css";

import { bigLogos, logos } from "./africaWalkPartners";

const AfricaWalk = () => {
	console.log(logos);
	useEffect(() => {
		const elems = document.querySelectorAll(".modals");
		M.Modal.init(elems, {
			inDuration: 500,
			outDuration: 1000,
			preventScrolling: true,
		});

		const elem = document.querySelectorAll(".carousel");
		M.Carousel.init(elem, {
			duration: 2000,

			padding: 20,
			shift: 20,
		});

		setInterval(() => {
			M.Carousel.getInstance(elem[0]).next();
		}, 3000);
	});

	return (
		<section>
			<div className="main">
				<img src={Billboard} alt="africa walk" />
			</div>
			<div className="section">
				<div className="container">
					<div className="main-values">
						<div className="woman3">
							<img src={aw} alt="image" className="responsive-img" />
						</div>
						<div
							className="values1"
							data-aos-anchor="#trigger-right"
							data-aos-anchor-placement="top-center"
						>
							<h6>What is Africa Walk</h6>
							{/* <h4>A Sector Agnostic Investment & Advisory Firm</h4> */}
							<p>
								Africa Walk’s aim is to build a bridge between Africa and the
								world. The future of Africa and the future of the world are
								linked, and to create the Africa that we want, collaboration and
								partnership must be our focus. Through Africa Walk, we are
								creating the platform that enables international companies &
								investors engage local companies, investors, policy makers &
								academia towards building the Africa of the future, in which
								global best practices are fused with local inputs thereby
								creating a truly ‘glo-cal’ future for Africa. For the inaugural
								Africa Walk in 2021, we brought global investors and companies
								to Nigeria and Kenya to meet with H.E. Lai Mohammed, FMDQ,
								senior government officials, local investors, technology
								companies and the local tech ecosystem{" "}
								<a
									className="modal-trigger hide-on-small-only"
									href="#modalAfricaWalk"
								>
									read more...
								</a>
							</p>
							<p className="hide-on-med-and-up">
								In 2022, we travelled to South Africa and Nigeria, where we met
								with H.E. Thabo Mbeki, H.E. Olusegun Obasanjo, and H.E. Joyce
								Banda, the Nigerian Stock Exchange, local investors, technology
								companies and the local tech ecosystem. In 2023, building on the
								traction from the prior years, we will visit Senegal and Nigeria
								to meet senior government officials such as, senior
								representatives from the Senegalese and new Nigerian government,
								local investors, and companies. This edition of Africa Walk will
								focus on the creative sectors in Africa. At Platform Capital,
								Africa Walk is key, it enables us global investors and companies
								to see the continent through our eyes. Thereby challenging the
								narrative and telling authentic stories that people can relate
								to and identify with. Africa is our center of gravity; we are
								obsessed with changing the Africa narrative and ensuring it is
								balanced. As a global investor, Platform Capital is seeking to
								use Africa Walk to bring together stakeholders of Africa to have
								dialogue that enables us to showcase the best of both worlds,
								thereby moving us towards the fulfillment of our mission.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div id="modalAfricaWalk" className="modals hide-on-small-only">
				<div className="modal-content">
					<h4>Africa Walk</h4>
					<p>
						In 2022, we travelled to South Africa and Nigeria, where we met with
						H.E. Thabo Mbeki, H.E. Olusegun Obasanjo, and H.E. Joyce Banda, the
						Nigerian Stock Exchange, local investors, technology companies and
						the local tech ecosystem. In 2023, building on the traction from the
						prior years, we will visit Senegal and Nigeria to meet senior
						government officials such as, senior representatives from the
						Senegalese and new Nigerian government, local investors, and
						companies. This edition of Africa Walk will focus on the creative
						sectors in Africa. At Platform Capital, Africa Walk is key, it
						enables us global investors and companies to see the continent
						through our eyes. Thereby challenging the narrative and telling
						authentic stories that people can relate to and identify with.
						Africa is our center of gravity; we are obsessed with changing the
						Africa narrative and ensuring it is balanced. As a global investor,
						Platform Capital is seeking to use Africa Walk to bring together
						stakeholders of Africa to have dialogue that enables us to showcase
						the best of both worlds, thereby moving us towards the fulfillment
						of our mission.
					</p>
				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close #b71c1c red darken-4 btn right">
						Close
					</a>
				</div>
			</div>
			<div className="stat">
				{/* <div className="statistics">
					<img src={statistics} alt="statistics" />
				</div> */}
				<div className="stats">
					<div className="portfolio">
						<h2>$1.5BN+</h2>
						<h6>Portfolio Valuation</h6>
					</div>

					<div className="portfolio">
						<h2>161+</h2>
						<h6>Portfolio companies</h6>
					</div>
					<div className="portfolio">
						<h2>70+</h2>
						<h6>Employees</h6>
					</div>
					<div className="portfolios">
						<h2>9</h2>
						<h6>Sector Verticals</h6>
					</div>
				</div>
			</div>
			<div className="btnColor">
				<div className="btnStyle">
					<a
						className="#0d47a1 blue darken-4 "
						type="submit"
						name="action"
						href="https://docs.google.com/forms/d/1LNIvWtiHZSfKD4AbmlUgBRyFgI4gZs8MTw6i2GkocAw/edit"
						target="_blank"
					>
						Apply to Exhibit
						<i className="material-icons">app_registration</i>
					</a>
					<a
						className="#0d47a1 blue darken-4 "
						type="submit"
						name="action"
						href="https://forms.gle/QY6ppfne7wWzUNmbA"
						target="_blank"
					>
						Register to Attend
						<i className="material-icons">how_to_reg</i>
					</a>
				</div>
			</div>

			<div className="partner_logos">
				<div className="container">
					<h2>Partners & Sponsors</h2>
					<div className="logo_display">
						{logos.map((logo, i) => (
							<div className="logo_img" key={i}>
								<img src={logo.image} alt={logo.alt} />
							</div>
						))}
					</div>
					<div className="carousel">
						{bigLogos.map((logo, i) => (
							<a className="carousel-item" href="#one!">
								<img src={logo.image} alt={logo.alt} />
							</a>
						))}
					</div>

					{/* <AfricaWalkSlider /> */}
				</div>
			</div>
		</section>
	);
};

export default AfricaWalk;
