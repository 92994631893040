import React, { Suspense } from "react";
import "./Home.css";
// import { Home1 } from "../../components/HomeComponents/Home1";
// import { Home2 } from "../../components/HomeComponents/Home2";
// import { Home3 } from "../../components/HomeComponents/Home3";
import { useDocumentTitle } from "../../components/TitleChange/TitleChange";
import { AnimatePage } from "../../components/Animate/AnimatePage";

const Home1 = React.lazy(() => import("../../components/HomeComponents/Home1"));
const Home2 = React.lazy(() => import("../../components/HomeComponents/Home2"));
const Home3 = React.lazy(() => import("../../components/HomeComponents/Home3"));

export const Home = () => {
  useDocumentTitle("Platform | Home");

  return (
    <AnimatePage>
      <div className="home">
        <Suspense fallback={<h1>Loading...</h1>}>
          <Home1 />
          <Home2 />
          <Home3 />
        </Suspense>
      </div>
    </AnimatePage>
  );
};
