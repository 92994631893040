import React from "react";
import BackToTop from "react-back-to-top-button";


export const Gototop = () => {
	return (
		<div className="gototop">
			<BackToTop
				showOnScrollUp
				showAt={100}
				speed={1500}
				easing="easeInOutQuint"
				style={{ background: "transparent" }}
			>
				<a
					className="btn-floating btn-large "
					style={{ background: "#1f2a3e" }}
				>
					<i className="large material-icons">arrow_upward</i>
				</a>
			</BackToTop>
		</div>
	);
};
