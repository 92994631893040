import React,{useEffect} from "react";

import groupPic1 from "../../images/groupPic1.png";
import M from "materialize-css"

import "./Grid.css";




export const Grid = () => {
  useEffect(() => {
 let elems = document.querySelectorAll(".materialboxed")
 M.Materialbox.init(elems, {})
}, [])
  return (
    <div className="gallery">
      <img src={groupPic1} className="responsive-img" />
    </div>
  );
};
