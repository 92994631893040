import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";

import { NavLink, Link, useNavigate } from "react-router-dom";

import "./Navbar.css";
import M from "materialize-css";

import logos from "../../images/logos.png";
import mess1 from "../../images/mess1.png";
import mess2 from "../../images/mess2.png";

import Naija from "../../images/Naija.png";
import southImg from "../../images/southImg.png";
import kenyaFlag from "../../images/kenyaFlag.png";
import ghanaFlag from "../../images/ghanaFlag.png";
import egyptFlag from "../../images/egyptFlag.png";
import sierraFlag from "../../images/sierraFlag.png";
import ugandaFlag from "../../images/ugandaFlag.png";
import mexicoFlag from "../../images/mexicoFlag.png";
import usaFlag from "../../images/usaFlag.png";

import CB from "../../images/CB.png";

export const Navbar = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    if (!name || !email || !message) {
      return M.toast({
        html: "Please fill out all the fields",
        classes: "rounded #d50000 red accent-4",
      });
    }

    emailjs
      .sendForm(
        "service_nfgvxup",
        "template_ae9d5tx",
        form.current,
        "yoSHw-PqkC-lzDdG1"
      )
      .then(
        (result) => {
          console.log(result.text);
          
          M.toast({
            html: "Message successfully sent",
            classes: "rounded #1b5e20 green darken-4",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const navigate = useNavigate();
  const home = window.location.pathname == "/";
  const portfolio = window.location.pathname == "/portfolio";
  const team = window.location.pathname == "/team";
  const about = window.location.pathname == "/about";
  const blog = window.location.pathname == "/blog";
  // const url = window.location.pathname.split("/").pop();

  useEffect(() => {
    const elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems, {
      edge: "right",
    });
    const elemM = document.querySelectorAll(".modal");
    M.Modal.init(elemM, {
      inDuration: 1000,
      opacity: 0.1,
    });
    const el = document.querySelectorAll(".tabs");
    M.Tabs.init(el, {});

    const elemD = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elemD, {
     
    });
  }, []);
 
  useEffect(() => {
    if (home || portfolio || team || about || blog) {
      localStorage.clear();
      localStorage.setItem("nigeria", "Nigeria");
      localStorage.setItem("nigerias", "Nigeria");
      document.getElementById("regions").innerHTML = "Nigeria";
      document.getElementById("regionse").innerHTML = "Nigeria";
    }
  }, [home, portfolio, team, about, blog]);

  useEffect(() => {
    const NIGERIAS = localStorage.getItem("nigerias");
    const SOUTHS = localStorage.getItem("souths");
    const KENYAS = localStorage.getItem("kenyas");
    const GHANAS = localStorage.getItem("ghanas");
    const EGYPTS = localStorage.getItem("egypts");
    const UGANDAS = localStorage.getItem("ugandas");
    const SIERRAS = localStorage.getItem("sierras");
    const MEXICOS = localStorage.getItem("mexicos");
    const USAS = localStorage.getItem("usas");

    if (NIGERIAS) {
      document.getElementById("regionse").innerHTML = NIGERIAS;
    } else if (KENYAS) {
      document.getElementById("regionse").innerHTML = KENYAS;
    } else if (GHANAS) {
      document.getElementById("regionse").innerHTML = GHANAS;
    } else if (SOUTHS) {
      document.getElementById("regionse").innerHTML = SOUTHS;
    } else if (EGYPTS) {
      document.getElementById("regionse").innerHTML = EGYPTS;
    } else if (UGANDAS) {
      document.getElementById("regionse").innerHTML = UGANDAS;
    } else if (SIERRAS) {
      document.getElementById("regionse").innerHTML = SIERRAS;
    } else if (MEXICOS) {
      document.getElementById("regionse").innerHTML = MEXICOS;
    } else {
      document.getElementById("regionse").innerHTML = USAS;
    }
  }, []);

  useEffect(() => {
    const NIGERIA = localStorage.getItem("nigeria");
    const SOUTH = localStorage.getItem("south");
    const KENYA = localStorage.getItem("kenya");
    const GHANA = localStorage.getItem("ghana");
    const EGYPT = localStorage.getItem("egypt");
    const UGANDA = localStorage.getItem("uganda");
    const SIERRA = localStorage.getItem("sierra");
    const MEXICO = localStorage.getItem("mexico");
    const USA = localStorage.getItem("usa");

    if (NIGERIA) {
      document.getElementById("regions").innerHTML = NIGERIA;
    } else if (KENYA) {
      document.getElementById("regions").innerHTML = KENYA;
    } else if (GHANA) {
      document.getElementById("regions").innerHTML = GHANA;
    } else if (SOUTH) {
      document.getElementById("regions").innerHTML = SOUTH;
    } else if (EGYPT) {
      document.getElementById("regions").innerHTML = EGYPT;
    } else if (UGANDA) {
      document.getElementById("regions").innerHTML = UGANDA;
    } else if (SIERRA) {
      document.getElementById("regions").innerHTML = SIERRA;
    } else if (MEXICO) {
      document.getElementById("regions").innerHTML = MEXICO;
    } else {
      document.getElementById("regions").innerHTML = USA;
    }
  }, []);

  const nigeria = (e) => {
    var imgNigeria = document.getElementById("nigeria").innerHTML;
    localStorage.setItem("nigeria", imgNigeria);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("ghana");
    document.getElementById("regions").innerHTML = imgNigeria;
  };

  const south = (e) => {
    var imgSouth = document.getElementById("south").innerHTML;
    localStorage.setItem("south", imgSouth);
    localStorage.removeItem("kenya");
    localStorage.removeItem("ghana");
    localStorage.removeItem("nigeria");

    document.getElementById("regions").innerHTML = imgSouth;
  };
  const kenya = (e) => {
    var imgKenya = document.getElementById("kenya").innerHTML;
    localStorage.setItem("kenya", imgKenya);
    localStorage.removeItem("south");
    localStorage.removeItem("ghana");
    localStorage.removeItem("nigeria");
    document.getElementById("regions").innerHTML = imgKenya;
  };
  const ghana = (e) => {
    var imgGhana = document.getElementById("ghana").innerHTML;
    localStorage.setItem("ghana", imgGhana);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    document.getElementById("regions").innerHTML = imgGhana;
  };
  const egypt = (e) => {
    var imgEgypt = document.getElementById("egypt").innerHTML;
    localStorage.setItem("egypt", imgEgypt);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    localStorage.removeItem("ghana");
    document.getElementById("regions").innerHTML = imgEgypt;
  };
  const uganda = (e) => {
    var imgUganda = document.getElementById("uganda").innerHTML;
    localStorage.setItem("uganda", imgUganda);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    localStorage.removeItem("ghana");
    localStorage.removeItem("egypt");
    document.getElementById("regions").innerHTML = imgUganda;
  };
  const sierra = (e) => {
    var imgSierra = document.getElementById("sierra").innerHTML;
    localStorage.setItem("sierra", imgSierra);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    localStorage.removeItem("ghana");
    localStorage.removeItem("egypt");
    localStorage.removeItem("uganda");
    document.getElementById("regions").innerHTML = imgSierra;
  };
  const mexico = (e) => {
    var imgMexico = document.getElementById("mexico").innerHTML;
    localStorage.setItem("mexico", imgMexico);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    localStorage.removeItem("ghana");
    localStorage.removeItem("egypt");
    localStorage.removeItem("uganda");
    localStorage.removeItem("sierra");
    document.getElementById("regions").innerHTML = imgMexico;
  };
  const usa = (e) => {
    var imgUSA = document.getElementById("usa").innerHTML;
    localStorage.setItem("mexico", imgUSA);
    localStorage.removeItem("south");
    localStorage.removeItem("kenya");
    localStorage.removeItem("nigeria");
    localStorage.removeItem("ghana");
    localStorage.removeItem("egypt");
    localStorage.removeItem("uganda");
    localStorage.removeItem("sierra");
    localStorage.removeItem("mexico");
    document.getElementById("regions").innerHTML = imgUSA;
  };

  

  const Nigeria = (e) => {
    var ImgNigeria = document.getElementById("nigerias").innerHTML;
    localStorage.setItem("nigerias", ImgNigeria);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("ghanas");

    document.getElementById("regionse").innerHTML = ImgNigeria;
  };

  const South = (e) => {
    var ImgSouth = document.getElementById("souths").innerHTML;
    localStorage.setItem("souths", ImgSouth);
    localStorage.removeItem("kenyas");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("nigerias");

    document.getElementById("regionse").innerHTML = ImgSouth;
  };
  const Kenya = (e) => {
    var ImgKenya = document.getElementById("kenyas").innerHTML;
    localStorage.setItem("kenyas", ImgKenya);
    localStorage.removeItem("souths");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("nigerias");

    document.getElementById("regionse").innerHTML = ImgKenya;
  };
  const Ghana = (e) => {
    var ImgGhana = document.getElementById("ghanas").innerHTML;
    localStorage.setItem("ghanas", ImgGhana);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");

    document.getElementById("regionse").innerHTML = ImgGhana;
  };
  const Egypt = (e) => {
    var ImgEgypt = document.getElementById("egypts").innerHTML;
    localStorage.setItem("egypts", ImgEgypt);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");
    localStorage.removeItem("ghanas");

    document.getElementById("regionse").innerHTML = ImgEgypt;
  };
  const Uganda = (e) => {
    var ImgUganda = document.getElementById("ugandas").innerHTML;
    localStorage.setItem("ugandas", ImgUganda);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("egypts");

    document.getElementById("regionse").innerHTML = ImgUganda;
  };
  const Sierra = (e) => {
    var ImgSierra = document.getElementById("sierras").innerHTML;
    localStorage.setItem("sierras", ImgSierra);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("egypts");
    localStorage.removeItem("ugandas");

    document.getElementById("regionse").innerHTML = ImgSierra;
  };
  const Mexico = (e) => {
    var ImgMexico = document.getElementById("mexicos").innerHTML;
    localStorage.setItem("mexicos", ImgMexico);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("egypts");
    localStorage.removeItem("ugandas");
    localStorage.removeItem("sierras");

    document.getElementById("regionse").innerHTML = ImgMexico;
  };
  const Usa = (e) => {
    var ImgUSA = document.getElementById("usas").innerHTML;
    localStorage.setItem("usas", ImgUSA);
    localStorage.removeItem("souths");
    localStorage.removeItem("kenyas");
    localStorage.removeItem("nigerias");
    localStorage.removeItem("ghanas");
    localStorage.removeItem("egypts");
    localStorage.removeItem("ugandas");
    localStorage.removeItem("sierras");
    localStorage.removeItem("mexicos");

    document.getElementById("regionse").innerHTML = ImgUSA;
  };

 
  return (
		<div>
			<div className="navbar-fixed">
				<nav className="nav-style top-bar">
					<div className="container-fluid">
						<div className="nav-wrapper">
							<Link to="/" className="brand-logo">
								<img src={logos} className="responsive-img" />
							</Link>
							<Link
								to="#"
								data-target="mobile-demo"
								className="sidenav-trigger right"
							>
								<i className="material-icons">menu</i>
							</Link>
							<ul className="right hide-on-med-and-down">
								<li>
									<NavLink to="/">HOME</NavLink>
								</li>

								<li>
									<NavLink to="/portfolio">PORTFOLIO</NavLink>
								</li>

								<li>
									<NavLink to="/team">TEAM</NavLink>
								</li>

								<li>
									<NavLink to="/blog">BLOG</NavLink>
								</li>

								<li>
									<NavLink to="/about">ABOUT US</NavLink>
								</li>
								<li>
									<NavLink to="/africawalk">AFRICA WALK</NavLink>
								</li>

								<li>
									<a href="http://platforminvestorportal.com" target="_blank">
										INVESTOR PORTAL
									</a>
								</li>

								<li>
									<button
										data-target="modal1"
										className="btn contact-button modal-trigger"
									>
										Contact us
									</button>
								</li>

								<li className="regions">
									<a
										className="dropdown-trigger btn"
										href="#"
										data-target="dropdown1"
										id="regions"
									>
										Nigeria
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
			<ul className="sidenav nav-styles" id="mobile-demo">
				<li>
					<NavLink className="sidenav-close" to="#!" style={{ float: "right" }}>
						<i
							className="fa fa-times"
							aria-hidden="true"
							style={{ color: "#A6AEBB" }}
						></i>
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/">
						HOME
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/portfolio">
						PORTFOLIO
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/team">
						TEAM
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/blog">
						BLOG
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/about">
						ABOUT US
					</NavLink>
				</li>
				<li>
					<NavLink className="sidenav-close" to="/africawalk">
						AFRICA WALK
					</NavLink>
				</li>
				<li>
					<a href="http://platforminvestorportal.com" target="_blank">
						INVESTOR PORTAL
					</a>
				</li>

				{/* <li>
          <button
            data-target="modal1"
            className="sidenav-close contact-button btn modal-trigger"
          >
            Contact us
          </button>
        </li> */}
				<li className="regions">
					<a
						className="dropdown-trigger btn"
						href="#"
						data-target="dropdown2"
						id="regionse"
					>
						Nigeria
					</a>
				</li>
			</ul>
			<ul id="dropdown1" className="dropdown-content">
				<li>
					<Link to="/" id="nigeria" onClick={nigeria}>
						<img src={Naija} alt="Nigeria flag" />
						Nigeria
					</Link>
				</li>
				<li>
					<Link to="/sa" onClick={south} id="south">
						<img src={southImg} alt="South Africa Flag" />
						South Africa
					</Link>
				</li>
				<li>
					<Link to="/kenya" id="kenya" onClick={kenya}>
						<img src={kenyaFlag} alt="Kenya Flag" />
						Kenya
					</Link>
				</li>
				<li>
					<Link to="/ghana" id="ghana" onClick={ghana}>
						<img src={ghanaFlag} alt="Ghana Flag" />
						Ghana
					</Link>
				</li>
				<li>
					<Link to="/uganda" id="uganda" onClick={uganda}>
						<img src={ugandaFlag} alt="Uganda Flag" />
						Uganda
					</Link>
				</li>
				<li>
					<Link to="/egypt" id="egypt" onClick={egypt}>
						<img src={egyptFlag} alt="Egypt Flag" />
						Egypt
					</Link>
				</li>
				<li>
					<Link to="/sierra" id="sierra" onClick={sierra}>
						<img src={sierraFlag} alt="Sierra Leone Flag" />
						Sierra Leone
					</Link>
				</li>
				<li>
					<Link to="/mexico" id="mexico" onClick={mexico}>
						<img src={mexicoFlag} alt="Mexico Flag" />
						Mexico
					</Link>
				</li>
				<li>
					<Link to="/usa" id="usa" onClick={usa}>
						<img src={usaFlag} alt="USA Flag" />
						USA
					</Link>
				</li>
			</ul>
			<ul id="dropdown2" className="dropdown-content">
				<li>
					<Link
						to="/"
						id="nigerias"
						onClick={Nigeria}
						className="sidenav-close"
					>
						<img src={Naija} alt="Nigeria flag" />
						Nigeria
					</Link>
				</li>
				<li>
					<Link to="/sa" onClick={South} id="souths" className="sidenav-close">
						<img src={southImg} alt="South Africa Flag" />
						South Africa
					</Link>
				</li>
				<li>
					<Link
						to="/kenya"
						id="kenyas"
						onClick={Kenya}
						className="sidenav-close"
					>
						<img src={kenyaFlag} alt="Kenya Flag" />
						Kenya
					</Link>
				</li>
				<li>
					<Link
						to="/ghana"
						id="ghanas"
						onClick={Ghana}
						className="sidenav-close"
					>
						<img src={ghanaFlag} alt="Ghana Flag" />
						Ghana
					</Link>
				</li>
				<li>
					<Link
						to="/uganda"
						id="ugandas"
						onClick={Uganda}
						className="sidenav-close"
					>
						<img src={ugandaFlag} alt="Uganda Flag" />
						Uganda
					</Link>
				</li>
				<li>
					<Link
						to="/egypt"
						id="egypts"
						onClick={Egypt}
						className="sidenav-close"
					>
						<img src={egyptFlag} alt="Egypt Flag" />
						Egypt
					</Link>
				</li>
				<li>
					<Link
						to="/sierra"
						id="sierras"
						onClick={Sierra}
						className="sidenav-close"
					>
						<img src={sierraFlag} alt="Sierra Leone Flag" />
						Sierra Leone
					</Link>
				</li>
				<li>
					<Link
						to="/mexico"
						id="mexicos"
						onClick={Mexico}
						className="sidenav-close"
					>
						<img src={mexicoFlag} alt="Mexico Flag" />
						Mexico
					</Link>
				</li>
				<li>
					<Link to="/usa" id="usas" onClick={Usa} className="sidenav-close">
						<img src={usaFlag} alt="USA Flag" />
						USA
					</Link>
				</li>
			</ul>
			{/* modal */}

			<div id="modal1" className="modal hide-on-med-and-down">
				<div className="modal-content">
					<div className="spread ">
						<div className="form-data">
							<div className="top-modal">
								<div className="modal-footers">
									<Link to="#!" className="modal-close waves-effect btn-flat">
										<img src={CB} alt="close-button" />
									</Link>
								</div>
							</div>
							<div className="connectus">
								<h4>Connect with us</h4>
							</div>
							<ul id="tabs-swipe-demo" className="tabs">
								<li className="tab col s3">
									<a className="active" href="#test-swipe-1">
										Message
									</a>
								</li>
								<li className="tab col s3">
									<a href="#test-swipe-2">Office</a>
								</li>
								<li className="tab col s3">
									<a href="#test-swipe-3">Email</a>
								</li>
							</ul>
							<div id="test-swipe-1" className="field1">
								<form ref={form} onSubmit={sendEmail}>
									<div className="input-field ">
										<input
											placeholder="Full name"
											type="text"
											id="name"
											name="name"
										/>

										<input
											placeholder="Email address"
											type="email"
											id="email"
											name="email"
										/>

										<textarea
											style={{ color: "white" }}
											placeholder="Leave us a message"
											className="materialize-textarea"
											type="textarea"
											id="subject"
											name="message"
										></textarea>

										<button
											className="btn waves-effect waves-light"
											type="submit"
										>
											Send message
										</button>
									</div>
								</form>
							</div>
							<div id="test-swipe-2">
								<div className="office-location">
									<h5>Office locations</h5>
									<div className="contact-main">
										<div className="contact-icone"></div>
										<div className="text1">
											<h6>Lagos, Nigeria</h6>
											<p>
												319, Akin Ogunlewe Rd, Off Ligali Ayorinde, Victoria
												Island, Lagos.
											</p>
										</div>
									</div>
									<div className="contact-main">
										<div className="contact-icone"></div>
										<div className="text1">
											<h6>Accra, Ghana</h6>
											<p>
												Atlantic Towers, Plot 16 Airport city, Accra, Ghana.
											</p>
										</div>
									</div>
									<div className="contact-main">
										<div className="contact-icone"></div>
										<div className="text1">
											<h6>Sandton, South Africa</h6>
											<p>
												13 Fredman Drive Freedman Towers Floor 8 Sandton Sandown
												2196.
											</p>
										</div>
									</div>
									<div className="contact-main">
										<div className="contact-icone"></div>
										<div className="text1">
											<h6>Mexico city, Mexico</h6>
											<p>
												Julio Verne 89 Col, Polanco Chaplepec, 11560, Mexico
												city.
											</p>
										</div>
									</div>
									<div className="contact-main">
										<div className="contact-icone"></div>
										<div className="text1">
											<h6>Dallas, Texas</h6>
											<p>6400 Pinecrest Dr, Piano, TX Kampala.</p>
										</div>
									</div>
								</div>
							</div>
							<div id="test-swipe-3">
								<div className="office-location">
									<div className="two-image">
										<img src={mess1} className="responsive-img" />
										<img src={mess2} className="responsive-img" />
									</div>
									<div className="two-image-text">
										<h5>Send us an email</h5>
										<h6>
											Reach out to us with a pitch deck or idea and our customer
											service team will respond to you shortly.
										</h6>
									</div>

									<div className="email-button">
										<a
											href="mailto:info@theplatformcapital.com"
											className="btn"
										>
											Send Email
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
