import React from "react";
import { Link } from "react-router-dom";
import './PageNotFound.css'

export const PageNotFound = () => {
  return (
    <div className="ErrorPage">
      <h1>404 Error</h1>
      <h2>Page Not Found</h2>
      {/* <Link to="/">Back to Platform Capital</Link> */}
    </div>
  );
};
