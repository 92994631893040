import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import moment from "moment";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import ReactPaginate from "react-paginate";


import videoIcon from "../../images/videoIcon.png";
import M from "materialize-css";

import connect from "../../images/connect.png";
import Divider from "../../images/Divider.png";
import linkedIn from "../../images/linkedIn.png";
import instagram from "../../images/instagram.png";
import youtube from "../../images/youtube.png";
import mail from "../../images/mail.png";
import merge from "../../images/merge.svg";
import tuma from "../../images/tuma.svg";
import omnicharge from "../../images/omnicharge.svg";


import invest1 from "../../images/invest1.png";
import invest2 from "../../images/invest2.png";
import invest3 from "../../images/invest3.png";

import "./LandingPage.css";
import  Newsletter  from "../../components/Newsletter/Newsletter";

export const LandingPage = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 9;
  const endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    const elemsSA = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(elemsSA, {});

    const elemsTooltip = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elemsTooltip, {});
  }, []);

  useEffect(() => {
    getData();
  }, [itemOffset, itemsPerPage]);

  const getData = () => {
    // const items = await axios.get("/post");
    sanityClient
      .fetch(
        `*[_type == "post" && regions == "South"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
      )
      .then((data) => {
        console.log(data);
        setCurrentItems(data.slice(itemOffset, endOffset));
        setLoading(false);
        setPageCount(Math.ceil(data.length / itemsPerPage));
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch items from another resources.

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  };

  const style1 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#39B68E",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style2 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#2B7FFF",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style3 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#D9B536",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style4 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "purple",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };

  const handlePageClick = async (event) => {
    // window.scroll({
    //   top: 300,
    //   left: 0,
    //   behavior: "smooth",
    // });
    // const items = await axios.get("/post");
    // const newOffset = (event.selected * itemsPerPage) % items.data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);
  };

  return (
    <AnimatePage>
      <section className="header">
        <div className="container">
          <div className="maine">
            {/* <div className="image77 hide-on-med-and-up">
            <img src={group2} alt="Image" className="responsive-img" />
          </div> */}

            <div className="home-section-sa">
              <h6>Wecome to South africa</h6>
              <h3>Emerging in the #3 ranked SMME market in Africa</h3>
              <p>
                Platform Capital South Africa was officially launched in 2021 to
                provide a solution to the growing demand for structure and
                funding for SMME’s. We plan to capitalize on this fast growing
                market.
              </p>

              <div className="watch-videos">
                <div className="link">
                  <a
                    href="https://www.businessinsider.co.za/nigerian-investor-wants-to-invest-r290m-in-to-20-sa-businesses-but-they-must-be-revolutionary-2022-3"
                    target="_blank"
                    className="btn"
                  >
                    Learn more about us
                  </a>
                </div>

                <div className="watch">
                  <a className=" modal-trigger" href="#modal6">
                    {/* <i className="fa fa-play-circle-o" aria-hidden="true"></i> */}
                    <img src={videoIcon} alt="watch-video" />
                    Watch video
                  </a>
                </div>
              </div>
              <div id="modal6" className="modal">
                <div className="modal-content">
                  <div className="video-frames">
                    {/* <video width="100%" height="400" controls>
                  <source src="https://www.youtube.com/watch?v=n56XwA7PsHc" />
                  Your browser does not support the video tag.
                </video> */}
                    <iframe
                      width="100%"
                      height="400"
                      src="https://youtu.be/lctwAQiPgfQ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="modal-footer modal-close">
                    <button>X</button>
                  </div>
                </div>
              </div>
            </div>
          

            <div className="icons-home hide-on-med-and-down">
              <a
                href="https://www.linkedin.com/company/theplatformcapital"
                target="_blank"
                className="tooltipped"
                data-position="left"
                data-tooltip="LinkedIn"
              >
                <img src={linkedIn} alt="linkedIn" />
              </a>
              <a
                href="https://www.instagram.com/theplatformcapital"
                target="_blank"
                className="tooltipped"
                data-position="left"
                data-tooltip="Instagram"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCBVDN0GxaRsmtXbsn11kmeQ/featured"
                target="_blank"
                className="tooltipped"
                data-position="left"
                data-tooltip="Youtube"
              >
                <img src={youtube} alt="youtube" />
              </a>
              <a
                href="info@theplatformcapital.com"
                target="_blank"
                className="tooltipped"
                data-position="left"
                data-tooltip="Mail us"
              >
                <img src={mail} alt="mail" />
              </a>
              <img src={connect} alt="connect" />

              <img src={Divider} alt="Divider" className="divider" />
            </div>
          </div>
        </div>
      </section>
      <div className="container main-section3-3">
        <div className="section3-3">
          <div className="portfolio">
            <h2>$1BN+</h2>
            <h6>Portfolio Valuation</h6>
          </div>
          <div className="portfolio">
            <h2>123+</h2>
            <h6>Portfolio companies</h6>
          </div>
          <div className="portfolio">
            <h2>60+</h2>
            <h6>Employees</h6>
          </div>
          <div className="portfolio">
            <h2>9</h2>
            <h6>Sector Verticals</h6>
          </div>
        </div>
      </div>

      <div className="our-footprint">
        <div>
          <h6 className="footprint">Regional Partners</h6>
          <h3 className="passionates">
            Our diverse footprint covers key industries across the continent
          </h3>
        </div>
      </div>

      <div className="container">
        <div className="sa-logos">
          <img src={merge} alt="logo" />

          <img src={tuma} alt="logo" />

          <img src={omnicharge} alt="logo" />
        </div>
      </div>
      <div className="container">
        <div className="recent">
          <h6>Recent Activities</h6>
          <h4>Our preliminary work is underway in South Africa</h4>
        </div>
      </div>

      {/* blog start */}
      <div className="container-fluid main-card">
        {!loading ? (
          currentItems.map((item, index) => {
            return (
              <div className="card-style" key={index}>
                {/* <Link to={`/blogpost/${item._id}`}> */}
                <Link
                  to={"/blogposts/" + item.slug.current}
                  key={item.slug.current}
                >
                  {item.mainImage ? (
                    <div className="card-image resize">
                      {/* <img src={item.image} className="responsive-img resize" /> */}
                      <img
                        src={item.mainImage.asset.url}
                        className="responsive-img"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
                <div className="content">
                  {item.tags == "Announcement" ? (
                    <div className="para">
                      <button style={style1}>{item.tags}</button>
                    </div>
                  ) : item.tags == "Technology" ? (
                    <div className="para">
                      <button style={style2}>{item.tags}</button>
                    </div>
                  ) : item.tags == "Tips" ? (
                    <div className="para">
                      <button style={style3}>{item.tags}</button>
                    </div>
                  ) : (
                    <div className="para">
                      <button style={style4}>Others</button>
                    </div>
                  )}

                  {/* <Link to={`/blogpost/${item._id}`}> */}
                  <div className="title-space">
                    <Link to={"/blogposts/" + item.slug.current}>
                      <h6
                      // dangerouslySetInnerHTML={{ __html: item.title }}
                      // style={{ height: "100px" }}
                      >
                        {item.title}
                      </h6>
                    </Link>
                  </div>

                  <div className="bottoms">
                    <span>
                      {moment(item.publishedAt).format("Do MMM YYYY")}
                      {/* ({new Date(item.publishedAt).toDateString()}) */}
                    </span>
                    {/* <span>{item.publishedAt}</span> */}
                    {/* <span>7 mins read</span> */}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <i
            className="fas fa-spinner fa-spin fa-5x spinner"
            style={{ color: "white" }}
          ></i>
        )}
      </div>
      {/* blog end */}

      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={"pageClass"}
        activeLinkClassName={"activeLink"}
        disabledClassName={"disable"}
      />

      {/* <div className="">
        <div className="blog-space">
          <div className="blog-post">
            <div>
              <img src={blogImage} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Social Impact</button>

              <h5>
                Introducing South Africa’s premier tech hub; the Unicorn Group.
              </h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
          <div className="blog-post">
            <div>
              <img src={blogImage1} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Technology</button>

              <h5>
                Platform Capital South Africa Office Launch. Business promise to
                boost the market with R200M in local business investments.
              </h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
          <div className="blog-post">
            <div>
              <img src={blogImage2} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Health care</button>

              <h5>Platform Capital attends Energy Week in South Africa</h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
          <div className="blog-post">
            <div>
              <img src={blogImage3} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Social Impact</button>

              <h5>
                Platform Capital SA celebrates World Health Day by distributing
                menstrual hygiene products to vulnerable girls in the community.
              </h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
          <div className="blog-post">
            <div>
              <img src={blogImage} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Social Impact</button>

              <h5>
                Platform Capital SA celebrates World Health Day by distributing
                menstrual hygiene products to vulnerable girls in the community.
              </h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
          <div className="blog-post">
            <div>
              <img src={blogImage} alt="blog-image" />
            </div>
            <div className="image-text">
              <button>Social Impact</button>

              <h5>
                Platform Capital SA celebrates World Health Day by distributing
                menstrual hygiene products to vulnerable girls in the community.
              </h5>
              <hr />
              <p>27 November, 2020</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container">
        <div className="investment">
          <h6>WHAT WE DO</h6>

          <h2>Our Investment Thesis</h2>

          <p className="investment1">
            We cover three key areas which are Principal Investments, Advisory
            and Investment Fiduciary.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="key-areas">
          <div className="area">
            {/* <i className="fas fa-landmark icons"></i> */}
            <img src={invest3} alt="image" />
            <h5>Principal Investment</h5>
            <p>
              We provide long-term capital to our investee companies, and work
              with them to achieve set goals. Long-term capital offers stability
              to the business, and allows entrepreneurs to focus on delivering
              the vision.
            </p>
          </div>
          <div className="area">
            {/* <i className="fas fa-landmark icons"></i> */}
            <img src={invest2} alt="image" />
            <h5>Advisory</h5>
            <p>
              Our holistic approach towards identifying opportunities,
              structuring, negotiations, capital raise and active management
              makes us a unique partner across all our engagements.
            </p>
          </div>
          <div className="area">
            {/* <i className="fas fa-landmark icons"></i> */}
            <img src={invest1} alt="image" />
            <h5>Investment Fiduciary</h5>
            <p>
              We leverage our senior leadership team’s experience across
              multiple sectors and geographies coupled with our
              investpreneurship skillset to manage third-party businesses and
              assets as fiduciaries.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <Newsletter />
      </div>
    </AnimatePage>
  );
};
