import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import M from "materialize-css";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import "./BlogPostSlider.css";

export const BlogPostSlidere = () => {
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const items = await axios.get("/post");
    sanityClient
      .fetch(
        `*[_type == "post" && regions == "Egypt"] | order(publishedAt asc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
      )
      .then((data) => {
        console.log(data);
        setLikes(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const style1 = {
    background: "#39B68E",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style2 = {
    background: "#2B7FFF",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style3 = {
    background: "#D9B536",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style4 = {
    background: "purple",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
    // margin: "0 auto",
  };
  return (
    <div>
      <div>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {/* <div className="story-item"> */}
          {!loading ? (
            likes.map((item, index) => {
              return (
                <div class="card-style" key={index}>
                  {/* <Link to={`/blogpost/${item._id}`}> */}
                  <Link
                    to={"/blogposte/" + item.slug.current}
                    key={item.slug.current}
                  >
                    {item.mainImage ? (
                      <div class="card-image">
                        {/* <img src={item.image} className="responsive-img resize" /> */}
                        <img
                          src={item.mainImage.asset.url}
                          className="responsive-img"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Link>
                  <div className="contents">
                    {item.tags == "Announcement" ? (
                      <div className="para">
                        <button style={style1}>{item.tags}</button>
                      </div>
                    ) : item.tags == "Technology" ? (
                      <div className="para">
                        <button style={style2}>{item.tags}</button>
                      </div>
                    ) : item.tags == "Tips" ? (
                      <div className="para">
                        <button style={style3}>{item.tags}</button>
                      </div>
                    ) : (
                      <div className="para">
                        <button style={style4}>Others</button>
                      </div>
                    )}

                    {/* <Link to={`/blogpost/${item._id}`}> */}
                    <Link to={"/blogposte/" + item.slug.current}>
                      <h6
                        // dangerouslySetInnerHTML={{ __html: item.title }}
                        style={{ height: "100px", color: "#fafbfb" }}
                      >
                        {item.title}
                      </h6>
                    </Link>

                    <div class="bottoms">
                      <span style={{ color: "#7a8699" }}>
                        {moment(item.publishedAt).format("Do MMM YYYY")}
                        {/* ({new Date(item.publishedAt).toDateString()}) */}
                      </span>
                      {/* <span>{item.publishedAt}</span> */}
                      {/* <span>7 mins read</span> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <i
              class="fas fa-spinner fa-spin fa-5x spinner"
              style={{ color: "white" }}
            ></i>
          )}
          {/* </div> */}
        </Carousel>
      </div>
    </div>
  );
};
