import React from "react";
import { NavLink, Link } from "react-router-dom";

import { NavHashLink } from "react-router-hash-link";
import "./Footer.css";
import logo from "../../images/logo.png";
import Accordion from "./Accordion";

import foot1 from "../../images/foot1.png";
import foot2 from "../../images/foot2.png";
import foot3 from "../../images/foot3.png";
import foot4 from "../../images/foot4.png";

export const Footer = () => {
	return (
		<div className="container">
			<Accordion />
			<div className="footers hide-on-small-only">
				<footer className=" transparent">
					<div className="row">
						<div className="col s12 m2 l2">
							<p className="white-style">Company</p>
							<ul>
								<li>
									<NavLink className="grey-style" to="#!">
										Our mission
									</NavLink>
								</li>
								<li>
									<NavLink className="grey-style" to="#!">
										Our goals
									</NavLink>
								</li>
								<li>
									<NavHashLink to="/#myCore">Our core values</NavHashLink>
								</li>
							</ul>
						</div>
						{/* second col */}

						<div className="col s12 m2 l2">
							<p className="white-style">Portfolio</p>
							<ul>
								<li>
									<NavHashLink to="/about/#about">Sectors</NavHashLink>
								</li>
								<li>
									<NavHashLink to="/portfolio/#company">Companies</NavHashLink>
								</li>
							</ul>
						</div>

						<div className="col s12 m2 l2">
							<p className="white-style">Industry</p>
							<ul>
								<li>
									<NavLink className="grey-style" to="#!">
										Our mission
									</NavLink>
								</li>
								<li>
									<NavLink className="grey-style" to="#!">
										Our goals
									</NavLink>
								</li>
								<li>
									<NavHashLink to="/#myCore">Our core values</NavHashLink>
								</li>
							</ul>
						</div>

						<div className="col s12 m2 l2">
							<p className="white-style">Media</p>
							<ul>
								<li>
									<NavLink className="grey-style" to="/blog">
										Blog
									</NavLink>
								</li>
								<li>
									<NavLink className="grey-style" to="#!">
										Events calendar
									</NavLink>
								</li>
								<li>
									<NavHashLink to="/portfolio/#news">Newsletter</NavHashLink>
								</li>
							</ul>
						</div>

						<div className="col s12 m2 l2">
							<p className="white-style">Our Team</p>
							<ul>
								<li>
									<NavHashLink to="/team/#teamed">
										Senior Management
									</NavHashLink>
								</li>

								<li>
									<NavHashLink to="/team/#teams">Teams</NavHashLink>
								</li>
							</ul>
						</div>

						<div className="col s12 m2 l2">
							<p className="white-style">Contact us</p>
							<ul>
								<li>
									<NavLink className="grey-style" to="#!">
										Office locations
									</NavLink>
								</li>
								<li>
									<NavLink className="grey-style" to="#!">
										info@theplatformcapital.com
									</NavLink>
								</li>
								<li>
									<NavLink className="grey-style" to="#!"></NavLink>
								</li>
							</ul>
						</div>
					</div>

					<div className="footer-copyright">
						<div style={{ marginTop: "10rem" }} className="last-section">
							<div className="b-logo">
								<Link to="#!" className="brand-logo">
									<img src={logo} className="responsive-img" />
								</Link>
							</div>
							<div>
								<div className="footer-icons">
									<a
										href="https://www.linkedin.com/company/theplatformcapital"
										target="_blank"
										className="tooltipped"
										data-position="bottom"
										data-tooltip="linkedIn"
									>
										<img src={foot1} alt="linkedIn" />
									</a>
									<a
										href="https://www.instagram.com/theplatformcapital"
										target="_blank"
										className="tooltipped"
										data-position="bottom"
										data-tooltip="instagram"
									>
										<img src={foot2} alt="instagram" />
									</a>
									<a
										href="https://www.youtube.com/channel/UCBVDN0GxaRsmtXbsn11kmeQ/featured"
										target="_blank"
										className="tooltipped"
										data-position="bottom"
										data-tooltip="youtube"
									>
										<img src={foot3} alt="youtube" />
									</a>

									<a
										href="info@theplatformcapital.com"
										target="_blank"
										className="tooltipped"
										data-position="bottom"
										data-tooltip="mail us"
									>
										<img src={foot4} alt="mail" />
									</a>
								</div>
							</div>
						</div>

						<div className="reserved-rights">
							© Platform Capital Group 2022. All rights reserved.
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};
