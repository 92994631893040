import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import "./PortfolioPage.css";
// import { Grid } from "../../components/Image-Grid/Grid";
import  Portfolio1  from "../../components/Portfolio1/Portfolio1";
import  Portfolio  from "../../components/Portfolio/Portfolio";
import  Newsletter  from "../../components/Newsletter/Newsletter";
import { useDocumentTitle } from "../../components/TitleChange/TitleChange";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import group1 from "../../images/group1.png";
import Aos from "aos";
import M from "materialize-css";

import { PorfolioCompany } from "../../components/PortfolioCompanies/PorfolioCompany";
import { PortfolioSlider } from "../../components/potfolioCompanySlide/PortfolioSlider";

export const PortfolioPage = () => {
  useDocumentTitle("Platform | Porfolio");

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    const ele = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(ele, {});
  }, []);

//  useEffect(() => {
//    const elems = document.querySelectorAll(".scrollspy");
//    M.ScrollSpy.init(elems, {});
//  }, []);

  return (
    <AnimatePage>
      <div>
        <div className=" container">
          <div className="D-grid1">
            <div className="grid-pic hide-on-large-only">
              {/* <Grid /> */}
              <div className="gallery">
                <img src={group1} className="responsive-img" />
              </div>
            </div>

            <div className="section1-team">
              <h3>Our Portfolio: Meet the change makers</h3>
              <h6>
                Platform Capital is a growth markets focused, sector agnostic,
                principal investment, and advisory firm. Platform Capital
                deploys patient, value accretive capital alongside.
              </h6>
            </div>
            <div className="grid-pic hide-on-med-and-down">
              {/* <Grid /> */}
              <div className="gallery">
                <img src={group1} className="responsive-img" />
              </div>
            </div>
          </div>
        </div>

        <div className=" container">
          <div className="section3-3">
            <div className="portfolio">
              <h2>$1.5BN+</h2>
              <h6>Portfolio Valuation</h6>
            </div>
            <div className="portfolio">
              <h2>161+</h2>
              <h6>Portfolio companies</h6>
            </div>
            <div className="portfolio">
              <h2>70+</h2>
              <h6>Employees</h6>
            </div>
            <div className="portfolios">
              <h2>11</h2>
              <h6>Nationalities</h6>
            </div>
          </div>
        </div>

        {/* mission */}

        <div className="our-footprint container">
          <div>
            <h6 className="footprint-portfolio">OUR MISSION</h6>
            <h3 className="passionate-portfolio" id="company">
              We are passionate about changing the African narrative
            </h3>
          </div>
        </div>

        {/* bookings africa */}
        <div>
          <PorfolioCompany />
        </div>

        <div className="container" style={{ marginTop: "5rem" }}>
          <PortfolioSlider />
        </div>

        {/* <Portfolio className="hide-on-large-only" /> */}

        {/* show more link */}
        <div className="container hide-on-med-and-down">
          {/* <div className="show-more" data-aos="zoom-in">
            <Link to="" class="waves-effect waves-light btn">
              Show more
            </Link>
          </div> */}

          <Portfolio1 />

          <Portfolio />
          <div id="news">
            <Newsletter />
          </div>
        </div>
      </div>
    </AnimatePage>
  );
};
