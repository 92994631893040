export const logos = [
	// {
	// 	image: "./image/cnn.png",
	// 	alt: "cnn",
	// },
	// {
	// 	image: "./image/bbc.png",
	// 	alt: "bbc",
	// },
	// {
	// 	image: "./image/netflix.png",
	// 	alt: "netflix",
	// },
	// {
	// 	image: "./image/star.png",
	// 	alt: "star",
	// },

	{
		image: "./image/am.png",
		alt: "am",
	},
	{
		image: "./image/rxail.png",
		alt: "rxail",
	},
	// {
	// 	image: "./image/bd.png",
	// 	alt: "bd",
	// },
	{
		image: "./image/pg.jpeg",
		alt: "pg",
	},

	// {
	// 	image: "./image/mahogany.jpg",
	// 	alt: "mahogany",
	// },

	// {
	// 	image: "./image/mm.jpg",
	// 	alt: "menomedia",
	// },
	// {
	// 	image: "./image/bff.png",
	// 	alt: "bff",
	// },
	// {
	// 	image: "./image/mt.png",
	// 	alt: "malatafo",
	// },
	// {
	// 	image: "./image/afex.png",
	// 	alt: "afex",
	// },
	// {
	// 	image: "./image/afig.png",
	// 	alt: "afig",
	// },

	{
		image: "./image/Udux.png",
		alt: "Udux",
	},
	{
		image: "./image/solo.png",
		alt: "solo",
	},
	{
		image: "./image/balmoral.jpeg",
		alt: "Balmoral",
	},
];

export const bigLogos = [
	{
		image: "./image/pinnaclemena.jpeg",
		alt: "pinnaclemena",
	},

	{
		image: "./image/BMI.png",
		alt: "BMI",
	},

	{
		image: "./image/cmg.webp",
		alt: "cmg",
	},
	{
		image: "./image/Deloitte.png",
		alt: "Deloitte",
	},
	{
		image: "./image/mac.png",
		alt: "mac",
	},
	{
		image: "./image/multichoice.png",
		alt: "multichoice",
	},
	{
		image: "./image/zeus.png",
		alt: "zeus",
	},
	{
		image: "./image/str.png",
		alt: "str",
	},
	{
		image: "./image/cream.png",
		alt: "cream",
	},
	{
		image: "./image/bf.jpg",
		alt: "bf",
	},
];
