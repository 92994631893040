import React from "react";
import Carousel from "react-multi-carousel";

import "./PortfolioSlider.css";

import adaLogo from "../../images/ada.png";
import strapLogo from "../../images/strapLogo.png";
import lipaLogo from "../../images/lipaLogo.png";
import virtuousLogo from "../../images/virtuousLogo.png";
import gareLogo from "../../images/gareLogo.png";
import mahoganyLogo from "../../images/mahoganyLogo.png";
import rusumoLogo from "../../images/rusumoLogo.png";

export const PortfolioSlider = () => {
  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 600,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 600,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <div className="ada">
          <div className="ada-top">
            <img src={adaLogo} className="responsive-img" />
          </div>
          <div className="ada-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Ada Labs is a global innovative and impact-driven fusion lab built
              on AI, blockchain and smart technologies
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              We aim at building the next generation of socially impactful and
              commercially driven entrepreneurs who will change the world from
              Africa. Here are some of our startups.
            </p>
            <a
              href="https://adalabsafrica.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Ada Animations
            </a>
          </div>
        </div>

        {/* strap */}

        <div className="strap">
          <div className="strap-top">
            <img src={strapLogo} className="responsive-img" />
          </div>
          <div className="strap-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Strap tecnologies built a wearable assistive technology device for
              the blind.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Strap develops the first substitute of the white cane for blind
              people. This is a wearable device that can detect any kind of
              obstacle and notify the user of possible collisions by a haptic
              language in the body.
            </p>
            <a href="" className="waves-effect waves-light btn">
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit strap Technologies
            </a>
          </div>
        </div>

        {/* lipalater */}

        <div className="lipa">
          <div className="lipa-top">
            <img src={lipaLogo} className="responsive-img" />
          </div>
          <div className="lipa-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Lipalater redefines shopping experience with flexible payment
              options.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Lipa Later provides consumers affordable alternative financing to
              purchase goods and services from online & offline retail stores
            </p>
            <a
              href="https://lipalater.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Lipalater
            </a>
          </div>
        </div>

        {/* virtuous score */}

        <div className="virtuous">
          <div className="virtuous-top">
            <img src={virtuousLogo} className="responsive-img brand-logo" />
          </div>
          <div className="virtuous-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Virtuouse core manufactured and deployed one of Africa’s pioneer
              reusable sanitary products.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Virtouscore Limited is a Nigerian producer and retailer of
              eco-friendly, washable and reusable sanitary pads with focus on
              young girls and women with little or no access to menstrual
              hygiene products.
            </p>
            <a
              href="https://virtuouspads.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Virtuous core
            </a>
          </div>
        </div>

        {/* gareFinance */}

        <div className="gare">
          <div className="gare-top">
            <img src={gareLogo} className="responsive-img brand-logo" />
          </div>
          <div className="gare-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Gare Finance provides fast and easy personal, business or salary
              advance loans.
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Gare Banking Group is a financial institution group of companies
              providing consumer lending, corporate banking advisory, asset
              purchase financing, LPO financing, among other corporate and
              retail financing and banking advisory services.
            </p>
            <a
              href="http://gareadvancefinancial.com/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Gare Finance
            </a>
          </div>
        </div>

        {/* mahogany */}

        <div className="mahogany">
          <div className="mahogany-top">
            <img src={mahoganyLogo} className="responsive-img brand-logo" />
          </div>
          <div className="mahogany-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Mahogany is developing and financing content production for local
              and global audience
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Mahogany Entertainment is an African based media investment
              company with expertise in developing and financing content
              production for both local and global audience.
            </p>
            <a
              href="https://mahoganymedia.ng/mahoganynew/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Mahogany Media
            </a>
          </div>
        </div>
        {/* rusumo */}

        <div className="rusumo">
          <div className="rusumo-top">
            <img src={rusumoLogo} className="responsive-img" />
          </div>
          <div className="rusumo-body">
            <h6 className="hide-on-med-and-down">Technology</h6>
            <h5>
              Rusumo Fund managers providing assets and wealth management
              services
            </h5>
            <h6 className="hide-on-large-only">Technology</h6>
            <p>
              Rusumo Fund is registered by the Securities & Exchange Commission
              as a Fund and Portfolio Manager. Rusumo provides asset and wealth
              management services giving superior returns to investor both
              locally and internationally
            </p>
            <a
              href="https://rusumo.com.ng/"
              target="_blank"
              className="waves-effect waves-light btn"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
              Visit Rusumo
            </a>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
