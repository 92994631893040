import React from "react";
import {  useEffect } from "react";

import portfo1 from "../../images/portfo1.png";
import portfo2 from "../../images/portfo1.png";
import portfo3 from "../../images/portfo3.png";
import "./BootstrapCarousel.css";

import M from "materialize-css";

export const BootstrapCarousel = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".slider");
    var instances = M.Slider.init(elems, {});
  });

  return (
    <div className="container123  hide-on-large-only">
      <div className="slider">
        <ul className="slides">
          <li>
            <div className="caption">
              <div className="flagg1"></div>
              <i
                className="fas fa-quote-left fa-2x"
                style={{ color: "#FAFBFB" }}
              ></i>
              
              <p className="port-para">
                Platform Capital’s investment philosophy truly embodies “beyond
                capital”, their guidance and support enabled me to improve on my
                business model, scale into 3 countries and develop partnerships
                in 3 continents.
              </p>

              <div className="parent">
                <div className="imagez">
                  <img src={portfo1} className="responsive-img" />
                </div>

                <div className="photo-text">
                  <h3>Fade Ogunro</h3>
                  <h5 className="caption2">CEO, Bookings Africa</h5>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="caption">
              <div className="flagg1"></div>
              <i
                className="fas fa-quote-left fa-2x"
                style={{ color: "#FAFBFB" }}
              ></i>
             
              <p className="port-para">
                Platform has been a strong investor and partner for Koniku
                providing invaluable financial & strategic support and
                governance frameworks that have been critical to the company’s
                growth and positioning.
              </p>
              <div className="parent">
                <div className="imagez">
                  <img src={portfo2} className="responsive-img" />
                </div>

                <div className="photo-text">
                  <h3>Osh Agabi</h3>
                  <h5 className="caption2">CEO, Koniku</h5>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="caption">
              <div className="flagg1"></div>
              <i
                className="fas fa-quote-left fa-2x"
                style={{ color: "#FAFBFB" }}
              ></i>
              
              <p className="port-para">
                Platform believed in us from the first meeting. I have only ever
                felt championed and like I have a family of support on my side.
                They have gone above and beyond for our fund and I am grateful.
              </p>
              <div className="parent">
                <div className="imagez">
                  <img src={portfo3} className="responsive-img" />
                </div>

                <div className="photo-text">
                  <h3>Maya H Famodu</h3>
                  <h5 className="caption2">MP, Ingressive Capital</h5>
                </div>
              </div>

             
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BootstrapCarousel;
