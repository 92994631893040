import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import "./Kenya.css";
// import "../Blog/Blog.css"
import moment, { relativeTimeRounding } from "moment";
import Video from "../Images/VideoIcon.svg";
// import SouthAfrica from "../../Images/KenyaNation.jpg";
import { Link } from "react-router-dom";
import LinkedIn from "../Images/LinkedIn.svg";
import Instagram from "../Images/Instgram.svg";
import Mail from "../Images/Mail.svg";
import YouTube from "../Images/YouTube.svg";
import Koko from "../Images/Koko.svg";
import Lipalater from "../Images/Lipalater.svg";
import Paycloud from "../Images/Paycloud.svg";
import Solar from "../Images/Solar.svg";
import Img1 from "../Images/Img1.svg";
import Img2 from "../Images/Img2.svg";
import Img3 from "../Images/Img3.svg";
import Img4 from "../Images/Img4.svg";
import Img5 from "../Images/Img5.svg";
import Img6 from "../Images/Img6.svg";
import Advisory from "../Images/Advisory.svg";
import Bank from "../Images/Bank.svg";
import Investment from "../Images/Investment.svg";
import  Newsletter  from "../../components/Newsletter/Newsletter";

function Kenya() {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 9;
  const endOffset = itemOffset + itemsPerPage;



 useEffect(() => {
   getData();
 }, [itemOffset, itemsPerPage]);

 const getData = () => {
   // const items = await axios.get("/post");
   sanityClient
     .fetch(
       `*[_type == "post" && regions == "Kenya"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      tags,
      categories,
      mainImage{
        asset->{
          _id,
          url
        }
      }
     
    }`
     )
     .then((data) => {
       console.log(data);
       setCurrentItems(data.slice(itemOffset, endOffset));
       setLoading(false);
       setPageCount(Math.ceil(data.length / itemsPerPage));
     })
     .catch((error) => {
       console.log(error);
     });

   // Fetch items from another resources.

   //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
 };

  const style1 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#39B68E",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style2 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#2B7FFF",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style3 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "#D9B536",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };
  const style4 = {
    // position: "absolute",
    top: "15rem",
    left: "1rem",
    background: "purple",
    border: "none",
    padding: "0.4rem",
    // width: "10rem",
    // height: "2rem",

    borderRadius: " 12px",
  };

  return (
    <AnimatePage>
      <div className="KenyaGeneralContainer">
        {/* <img src={SouthAfrica} alt='' /> */}
        <header className="KenyaParent">
          <div className="container">
            <div className="KenyaMainContainer">
              <h6>Wecome to Kenya</h6>
              <h3>Seizing Kenya’s low risk investment opportunities </h3>
              <p>
                Platform Capital Kenya was officially launched in 2021 to
                provide a stable and safe option for foreign investors looking
                to break into Africa’s diverse market with minimal risks..
              </p>

              <div className="KenyaButton">
                <Link to="">
                  <button>Learn more about us</button>
                </Link>
              </div>

              <div className="KenyaVideoClick">
                <div className="KenyaSubVid1">
                  <img src={Video} alt="" />
                </div>
                <div className="KenyaSubVid1">
                  <p>Watch video</p>
                </div>
              </div>
            </div>
          </div>

          <div className="KenyaSocialMediaHandles">
            <img src={LinkedIn} alt="" />
            <img src={Instagram} alt="" />
            <img src={Mail} alt="" />
            <img src={YouTube} alt="" />
          </div>
        </header>

        <div className="Kenya2GeneralConatiner">
          <div className="container">
            <div className="Kenya2MainContainer">
              <div className="Kenya2Children1">
                <h2>$1BN+</h2>
                <p>Portfolio Valuation</p>
              </div>

              <div className="Kenya2Children">
                <h2>123+</h2>
                <p>Portfolio companies</p>
              </div>

              <div className="Kenya2Children">
                <h2>60+</h2>
                <p>Employees</p>
              </div>

              <div className="Kenya2Children">
                <h2>9</h2>
                <p>Sector Verticals</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="Kenya3MainContainer">
            <h6>Regional Partners</h6>
            <h3>
              Our diverse footprint covers key industries across the continent
            </h3>
          </div>
        </div>

        <div className="container">
          <div className="Kenya4MainContainer">
            <img src={Koko} alt="" />
            <img src={Lipalater} alt="" />
            <img src={Paycloud} alt="" />
            <img src={Solar} alt="" />
          </div>
        </div>
        {/* blog post start */}

        <div className="container-fluid main-card">
          {!loading ? (
            currentItems.map((item, index) => {
              return (
                <div class="card-style" key={index}>
                  {/* <Link to={`/blogpost/${item._id}`}> */}
                  <Link
                    to={"/blogpostk/" + item.slug.current}
                    key={item.slug.current}
                  >
                    {item.mainImage ? (
                      <div class="card-image resize">
                        {/* <img src={item.image} className="responsive-img resize" /> */}
                        <img
                          src={item.mainImage.asset.url}
                          className="responsive-img"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Link>
                  <div className="content">
                    {item.tags == "Announcement" ? (
                      <div className="para">
                        <button style={style1}>{item.tags}</button>
                      </div>
                    ) : item.tags == "Technology" ? (
                      <div className="para">
                        <button style={style2}>{item.tags}</button>
                      </div>
                    ) : item.tags == "Tips" ? (
                      <div className="para">
                        <button style={style3}>{item.tags}</button>
                      </div>
                    ) : (
                      <div className="para">
                        <button style={style4}>Others</button>
                      </div>
                    )}

                    {/* <Link to={`/blogpost/${item._id}`}> */}
                    <div className="title-space">
                      <Link to={"/blogpostk/" + item.slug.current}>
                        <h6
                        // dangerouslySetInnerHTML={{ __html: item.title }}
                        // style={{ height: "100px" }}
                        >
                          {item.title}
                        </h6>
                      </Link>
                    </div>

                    <div class="bottoms">
                      <span>
                        {moment(item.publishedAt).format("Do MMM YYYY")}
                        {/* ({new Date(item.publishedAt).toDateString()}) */}
                      </span>
                      {/* <span>{item.publishedAt}</span> */}
                      {/* <span>7 mins read</span> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <i
              class="fas fa-spinner fa-spin fa-5x spinner"
              style={{ color: "white" }}
            ></i>
          )}
        </div>

        {/* blog post end */}

        <div className="container">
          <div className="Kenya6GeneralContainer">
            <div className="Kenya6MainContainer">
              <h6>WHAT WE DO</h6>
              <h3>Our Investment Thesis</h3>
              <p>
                We cover three key areas which are Principal Investments,
                Advisory and Investment Fiduciary.
              </p>
            </div>

            <div className="Kenya6Parent">
              <div className="Kenya6Children">
                <img src={Advisory} alt="" />
                <h5>Principal Investment</h5>
                <p>
                  We provide long-term capital to our investee companies, and
                  work with them to achieve set goals. Long-term capital offers
                  stability to the business, and allows entrepreneurs to focus
                  on delivering the vision.
                </p>
              </div>

              <div className="Kenya6Children">
                <img src={Investment} alt="" />
                <h5>Advisory</h5>
                <p>
                  Our holistic approach towards identifying opportunities,
                  structuring, negotiations, capital raise and active management
                  makes us a unique partner across all our engagements.
                </p>
              </div>

              <div className="Kenya6Children">
                <img src={Bank} alt="" />
                <h5>Investment Fiduciary</h5>
                <p>
                  We leverage our senior leadership team’s experience across
                  multiple sectors and geographies coupled with our
                  investpreneurship skillset to manage third-party businesses
                  and assets as fiduciaries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Newsletter />
        </div>
      </div>
    </AnimatePage>
  );
}

export default Kenya;
