import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import M from "materialize-css";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";

import sharePost from "../../images/sharePost.png";
import goBack from "../../images/goBack.png";
import line from "../../images/line.png";

import imageUrlBuilder from "@sanity/image-url";



import  Newsletter  from "../../components/Newsletter/Newsletter";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import { BlogPostSlideru } from "../../components/blogPostSlider/BlogPostSlideru";
// import { POSITION } from "react-toastify/dist/utils";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const style = {
  background: "transparent",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 30px",
};

export const BlogPostu = () => {
  const shareUrl = window.location.href;
  console.log(shareUrl);
  // const { userpost } = useParams();
  const { slug } = useParams();

  const [post, setPost] = useState([]);

  // const [arr, setArr] = useState([]);
  useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {});
  }, []);

  useEffect(() => {
    const elems = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(elems, {});
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setData();
    // loadData();
  }, [slug]);

  const setData = () => {
    // console.log(userpost);

    // const items = await axios.get(`/post/${userpost}`);
    // console.log(items);

    sanityClient
      .fetch(
        `*[slug.current == $slug]{
        title,
        slug,
        tags,
        excerpt,
        publishedAt,
        mainImage{
          asset->{
            url,
            _id
          }
        },
        body,
        'name':author->name,
        'authorImage':author->image
        
        
      }`,
        { slug }
      )
      .then((data) => {
        console.log(data[0]);
        setPost(data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  

  const style1 = {
    background: "#39B68E",
    border: "none",
    padding: "0.4rem",
    // width: "4rem",
    // height: "2rem",
    color: "white",
    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style2 = {
    background: "#2B7FFF",
    border: "none",
    padding: "0.4rem",
    // width: "4rem",
    // height: "2rem",
    color: "white",
    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style3 = {
    background: "#D9B536",
    border: "none",
    padding: "0.4rem",
    // width: "4rem",
    // height: "2rem",
    color: "white",
    borderRadius: " 12px",
    // margin: "0 auto",
  };
  const style4 = {
    background: "purple",
    border: "none",
    padding: "0.4rem",
    // width: "4rem",
    // height: "2rem",
    color: "white",
    borderRadius: " 12px",
    // margin: "0 auto",
  };
  return (
    <div className="container">
      <div className="blogPost">
        <div className="goup ">
          <div className="post-link">
            <Link to="/uganda" className="waves-effect waves-light btn">
              <img src={goBack} alt="back-image" />
              Go Back
            </Link>
          </div>
          <div className="goup-title">
            {/* <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2> */}
            <h2>{post.title}</h2>
          </div>
        </div>
        <div className="bottom1">
          {post.tags == "Announcement" ? (
            <button style={style1}>{post.tags}</button>
          ) : post.tags == "Technology" ? (
            <button style={style2}>{post.tags}</button>
          ) : post.tags == "Tips" ? (
            <button style={style3}>{post.tags}</button>
          ) : (
            <div style={style4}>
              <button>Others</button>
            </div>
          )}

          <h6>{moment(post.publishedAt).format("Do MMM YYYY")}</h6>
          {/* <p>7 mins read</p> */}
          {/* <div className="bottom1-image hide-on-small-only">
                <img
                  src={urlFor(item.authorImage).url()}
                  className="responsive-img materialboxed"
                />
                <h6>{item.name}</h6>
              </div> */}
        </div>
        {/* <div className="bottom1-image hide-on-med-and-up">
              <img
                src={urlFor(item.authorImage).url()}
                className="responsive-img materialboxed"
              />
              <h6>{item.name}</h6>
            </div> */}
        {post.mainImage ? (
          <div className="blogPost-header header-image">
            <img
              src={post.mainImage.asset.url}
              className="responsive-img mainImage-style"
            />
          </div>
        ) : (
          ""
        )}
        {/* mobile */}

        {/* <ShareSocial
              style={style}
              url={shareUrl}
              socialTypes={["facebook", "twitter", "linkedin"]}
            /> */}

        <div className="socia-media-icons">
          <FacebookShareButton className="socia" url={shareUrl}>
            <FacebookIcon size={30} round={true} />
          </FacebookShareButton>

          <TwitterShareButton className="socia" url={shareUrl}>
            <TwitterIcon size={30} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton className="socia" url={shareUrl}>
            <LinkedinIcon size={30} round={true} />
          </LinkedinShareButton>
          <PinterestShareButton className="socia" url={shareUrl}>
            <PinterestIcon size={30} round={true} />
          </PinterestShareButton>
          <RedditShareButton className="socia" url={shareUrl}>
            <RedditIcon size={30} round={true} />
          </RedditShareButton>
          <TumblrShareButton className="socia" url={shareUrl}>
            <TumblrIcon size={30} round={true} />
          </TumblrShareButton>
          <div className="share-post">
            <img src={sharePost} alt="share-icons" />
          </div>
          <div className="line-post hide-on-med-and-down">
            <img src={line} alt="share-icons" />
          </div>
        </div>

        {/* <div className="overlay">
              <div className="footer-icons">
                <Link to="">
                  <i
                    className="fab fa-linkedin  fo"
                    style={{ color: "#EBEDF0" }}
                  ></i>
                </Link>
                <Link to="">
                  <i
                    className="fab fa-twitter fooed"
                    style={{ color: "#EBEDF0" }}
                  ></i>
                </Link>
                <Link to="">
                  <i
                    className="fab fa-instagram  fooed"
                    style={{ color: "#EBEDF0" }}
                  ></i>
                </Link>
                <Link to="">
                  <i
                    className="fab fa-facebook  fooed"
                    style={{ color: "#EBEDF0" }}
                  ></i>
                </Link>
              </div>
            </div> */}
        <div className="post-item">
          <div className="post-items">
            {/* <h4 dangerouslySetInnerHTML={{ __html: item.subtitle }}></h4> */}
            {/* <hr className="subtitle-hr" /> */}
            {/* <h4>{item.excerpt}</h4> */}
            {/* <p dangerouslySetInnerHTML={{ __html: item.body }}></p> */}
            <div className="body-post">
              <BlockContent
                blocks={post.body}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
                className="block-content"
              />
            </div>

            {/* <p>{item.body}</p> */}
          </div>
          {/* <div className="post-rule">
                <hr className="post-hr" />
              </div> */}
        </div>
      </div>

      {/* stories you may like */}

      <div className="stories">
        <h3>Stories you may like</h3>
      </div>
      <div className="">
        <BlogPostSlideru />
      </div>

      {/* <div id="modal5" className="modal">
        <div className="modal-content">
          <Newsletter />
        </div>
      </div> */}

      {/* blog-link */}

      <div className="main-card1">
        {/* {arr.map((item) => {
          return (
            <div className="card-style">
              <Link to={`/blogpost/${item._id}`}>
                <div className="card-image">
                  <img src={item.image} className="responsive-img resize" />
                </div>
              </Link>
              <div className="content">
                <div className="para">
                  <p>{item.tag}</p>
                </div>
                <Link to={`/blogpost/${item._id}`}>
                  <h6>{item.title}</h6>
                </Link>
                <div className="bottom">
                  <span>{moment(item.date).format("Do MMM YYYY")}</span>
                  <span>7 mins read</span>
                </div>
              </div>
            </div>
          );
        })} */}
      </div>

      <Newsletter />
    </div>
  );
};

{
  /* <div>
        <div className="comments">
          <h3>Comments</h3>
          <Link to="" className="waves-effect waves-light btn">
            Leave a reply
          </Link>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div className="reply">
          <div className="first-word">
            <h6>LO</h6>
          </div>
          <div className="details">
            <h6>Lionel Okoeguale</h6>
            <p>December 20, 2021; 11:21 am</p>
          </div>
          <div className="Reply">
            <button className="btn">Reply</button>
          </div>
        </div>

        <div className="comments">
          <h6>
            Leo orci dolor, blandit id magna aenean. Tellus interdum lacus
            euismod pharetra, ultrices eget tristique nibh. Orci sed est neque
            ac diam mauris. Eu tempor condimentum sit tincidunt dolor quisque
            pretium quam molestie. Ullamcorper tortor etiam id sed lobortis.
          </h6>
        </div>
        <div className="input-size">
          <div className="inputs">
            <div className="input-field">
              <i className="material-icons prefix" style={{ color: "#6BA6FF" }}>
                comment
              </i>
              <input id="icon_prefix" type="email" />
              <label for="icon_prefix">Comment...</label>
            </div>
            <span>
              <button className="btn btn-large" type="submit">
                Submit
              </button>
            </span>
          </div>
        </div>
      </div> */
}
