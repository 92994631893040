import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid } from "../../components/Image-Grid/Grid";
import "./Team.css";
import M from "materialize-css";

import  Newsletter  from "../../components/Newsletter/Newsletter";

import senior1 from "../../images/senior1.png";
import senior2 from "../../images/senior2.png";
import senior3 from "../../images/senior3.png";

import senior5 from "../../images/senior5.png";

import senior7 from "../../images/senior7.png";
import senior8 from "../../images/senior8.png";
import senior9 from "../../images/senior9.png";

import senior13 from "../../images/senior13.png";
import senior14 from "../../images/senior14.png";
import senior15 from "../../images/senior15.png";
import senior16 from "../../images/senior16.png";
import r1 from "../../images/r1.png";
import r2 from "../../images/r2.png";
import r3 from "../../images/r3.png";
import r4 from "../../images/r4.png";
import r5 from "../../images/r5.png";
import CB from "../../images/CB.png";
import luchy from "../../images/luchy.png";
import videoIcon from "../../images/videoIcon.png";

import asso1 from "../../images/asso1.png";

import asso6 from "../../images/asso6.png";

import ana1 from "../../images/ana1.png";
import ana2 from "../../images/ana2.png";
import ana3 from "../../images/ana3.png";
import ana4 from "../../images/ana4.png";
import ana5 from "../../images/ana5.png";
import ana6 from "../../images/ana6.png";
import ana7 from "../../images/ana7.png";

import ana9 from "../../images/ana9.png";
import ana10 from "../../images/ana10.png";

import ana13 from "../../images/ana13.png";
import ana14 from "../../images/ana14.png";
import ana15 from "../../images/ana15.png";
import ana16 from "../../images/ana16.png";
import ana17 from "../../images/ana17.png";
import ana18 from "../../images/ana18.png";

import ana20 from "../../images/ana20.png";
import ana21 from "../../images/ana21.png";

import ana23 from "../../images/ana23.png";
import ana24 from "../../images/ana24.png";

import ana26 from "../../images/ana26.png";

import ana30 from "../../images/ana30.png";
import ana31 from "../../images/ana31.png";
import lionel from "../../images/lionel.png";

import akin from "../../images/akin.png";
import { useDocumentTitle } from "../../components/TitleChange/TitleChange";
import { AnimatePage } from "../../components/Animate/AnimatePage";
import Aos from "aos";

export const Team = () => {
  useDocumentTitle("Platform | Team");
  useEffect(() => {
    const ele = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(ele, {
      dismissible: false,
    });
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  useEffect(() => {
    const elemDoc = document.querySelectorAll(".modal");
    M.Modal.init(elemDoc, {});
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const removeIFrame = () => {
    const frame = (document.getElementById("iframe").src = "");
    window.location.reload();
  };

  return (
    <AnimatePage>
      <div className="container">
        <div className="D-grid">
          <div className="grid-pic hide-on-large-only">
            <Grid />
          </div>

          <div className="section1-team">
            <h3>Meet the minds changing the African narrative</h3>
            <h6>
              We are a high skilled family that is obsessed about changing the
              African narrative, value creation and sustainable development
              accross our continent.
            </h6>
            <div className="link">
              <Link to="/about" className="btn">
                Learn more about us
              </Link>
            </div>
          </div>
          <div className="grid-pic hide-on-med-and-down">
            <Grid />
          </div>
        </div>
        {/* founder */}

        <div className="roots">
          <div className="main-values-team">
            <div className="woman-team1 show-on-medium hide-on-small-only hide-on-large-only">
              <img src={akin} alt="image" className="responsive-img" />
            </div>
            <div className="our-styles hide-on-large-only">
              <h6>Meet the Founder and CEO</h6>
              <h2>Dr. Akintoye Akindele</h2>
            </div>
            <div className="woman-team1 hide-on-med-only">
              <img src={akin} alt="image" className="responsive-img" />
            </div>
            <div className="values-team">
              <h6 className="hide-on-med-and-down">Meet the Founder and CEO</h6>
              <h2 className="hide-on-med-and-down">Dr. Akintoye Akindele</h2>
              <p>
                The Founder & Chairman of Platform Capital, is a lecturer and
                nation builder, focused on cultivating the minds of future
                leaders at top learning institutions around the globe. He is
                also an investor, entrepreneur, and philanthropist, passionate
                about reclaiming and rewriting the history that drives the
                African continent.{" "}
                <a href="https://www.drakintoyeakindele.com">read more...</a>
              </p>
              <button data-target="modal-vid" className="btn modal-trigger">
                <img src={videoIcon} alt="play-video" />
                Watch Introductory Video
              </button>
            </div>
          </div>
        </div>
        {/* <div>
          <div id="modal-vid" className="modal">
            <div className="modal-footer modal-close">
              <img src={CB} />
            </div>
            <div className="modal-content">
              <div className="video-frames">
                <iframe
                  width="100%"
                  height="420"
                  src="https://www.youtube.com/embed/lctwAQiPgfQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div> */}

        <div id="modal-vid" className="modal">
          <div className="modal-content">
            <div className="modal-footer modal-close">
              <img src={CB} onClick={removeIFrame} alt="close button" />
            </div>
            <div className="video-container responsive-video">
              <iframe
                id="iframe"
                width="853"
                height="480"
                src="https://www.youtube.com/embed/lctwAQiPgfQ"
                title="Akintoye Akindele Short Bio"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        {/* senior management team */}
        <div className="senior-team" id="teamed">
          <h1>Our Senior Management Team</h1>
          <p>
            We put together a 18-person senior management team with over 340+
            years of combined investment experience (Private Equity, Venture
            Capital, Investment Banking, Finance, Investor Relations,
            Operations) across multiple markets.
          </p>
        </div>

        <div className="senior-pic">
          <div className="pics">
            <img src={senior1} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Dolapo Ogunmekan</h6>
                <p>Managing Director</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior2} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Ponmile Osibo</h6>
                <p>Partner</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior3} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Solabomi Adedeji</h6>
                <p>Partner</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={senior4} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Olubunmi Odunowo</h6>
                <p>Partner</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={senior5} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Dr. Benedicta Oyiana</h6>
                <p>Partner</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={senior7} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Ngozi Suilman</h6>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior8} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Akudo Nweke</h6>
                <p>Head Legal</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={r1} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Prishani Satyapal</h6>
                <p>Partner</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={r2} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Cesar Baena</h6>
                <p>Partner</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={r3} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Bianca Loew</h6>
                <p>Country Lead, Mexico</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={r4} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Mercy Asiima</h6>
                <p>Country Lead, Uganda</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior9} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Adenike Okebu</h6>
                <p>Head, Audit & Compliance</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={senior12} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Chukwudalu Akabogu</h6>
                <p>Head, Energy Investments</p>
              </div>
            </div>
          </div> */}

          {/* <div className="pics">
            <img src={senior10} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Toyin Ogunbajo</h6>
                <p>HR Manager</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={r5} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Adeniyi Olowoniyi</h6>
                <p>Chief Information Officer</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior14} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Bowofade Elegbede</h6>
                <p>Head, Tech Investments</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={senior15} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Adesola Adesugba</h6>
                <p>Head, Brand Capital</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={senior16} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Dubem Okafor</h6>
                <p>Head, IT Infrastructure</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={senior13} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Akintoyesse Ibironke</h6>
                <p>Finance Manager</p>
              </div>
            </div>
          </div>
        </div>

        {/* associates */}

        <div className="associates" id="teams">
          <h2>Our Team</h2>
        </div>
        <div className="senior-pic">
          {/* <div className="pics">
            <img src={asso5} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Damilola Obidairo </h6>
                <p>Program Manager</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={asso6} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Zainab Idumah</h6>
                <p>Associate</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={asso1} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Oluwasuen Oladimeji</h6>
                <p>Associate, Portfoli Support</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={asso2} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Eyitemi Adebowale</h6>
                <p>Senior associate, Brand Capital</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={ana1} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Enare Agbor</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana2} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Joshua Ojewunmi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana31} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Mabel Omogiate</h6>
                <p>Executive Assitant </p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana3} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Olumide Bankole</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={ana5} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Modupe Akinyemi </h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana6} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Mobolade Salami</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>

          {/* <div className="pics">
            <img src={ana8} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Oluwatobi Balogun</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={ana7} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Omotayo Ademuwagun</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana9} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Joshua Adeyemo</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana10} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Suilaman Odesina </h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={ana11} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Titilayo Dahnusi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}
          {/* <div className="pics">
            <img src={ana12} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Tamilore Akapo</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={ana13} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Adetutu Adeite</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana14} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Alex Tega</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana15} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Oyebanmiji Ojetimi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana16} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Martha Ayikah</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana17} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>John Akinyemi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana18} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Taiwo Shobande</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana20} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Tobiloba Adewunmi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={ana29} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Emeka Chukwurah</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={luchy} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Oluchi Amamchukwu</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana21} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Motunrayo Dasilva</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={ana23} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Aisha Adegbenro</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana24} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Obianuju Onyebuagu</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={ana26} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Nadia Ebong</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={ana4} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Ademidun Akindele</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={ana32} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Teniola Odekunle</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}
          <div className="pics">
            <img src={lionel} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Lionel Okoeguale</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>

          <div className="pics">
            <img src={ana30} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Jennifer Omoruyi</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div>
          {/* <div className="pics">
            <img src={ana22} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Olamide Oluwatoke</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}

          {/* <div className="pics">
            <img src={asso3} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Ebunoluwa Surname</h6>
                <p>Associate</p>
              </div>
            </div>
          </div>
          <div className="pics">
            <img src={asso4} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Olatunde King</h6>
                <p>Legal</p>
              </div>
            </div>
          </div> */}
        </div>

        {/* analysts */}

        {/* <div className="associates">
          <h2>Analysts</h2>
        </div> */}
        {/* <div className="senior-pic"> */}
        {/* <div className="pics">
            <img src={ana19} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Lionel Okoeguale</h6>
                <p>Analyst</p>
              </div>
            </div>
          </div> */}

        {/* <div className="pics">
            <img src={ana25} alt="Avatar" className="image-team" />
            <div className="overlay-team">
              <div className="text-team">
                <h6>Opeyemi Oyewole</h6>
                <p>Designation</p>
              </div>
            </div>
          </div>  */}
        {/* </div> */}

        <Newsletter />
      </div>
    </AnimatePage>
  );
};
